<template>
    <div>
        <div class="right-menu shipping-right">

            <subHeader pageName="CONTACTS" />

            <tutorialsAction />

            <div class="db_top">
                <div class="db-container">

                    <moduleStats/>

                    <!-- search block starts here -->
                    <moduleSearch :moduleName="'contact'" :disable="tourIsActive" ref="module_search"/>
                    <!-- search block ends here -->

                    <!-- data table starts here -->
                    <div class="tableMainWrapBox" style="margin-top: 25px;position: relative;" id="preview-record">
                        <div class="tableScrollWrap" ref="contactsTable">
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            <div class="ctmCheckWrap">
                                                <label class="ctmTableCheckContainer">
                                                    <input 
                                                        type="checkbox"
                                                        @click="toggleIsCheckedAll()"
                                                        :checked="checkedItems.length != 0 && checkedItems.length == contactsList.length"
                                                        :disabled="tourIsActive || this.previewForm.id != ''"
                                                    />
                                                    <span class="checkmark"></span>
                                                </label>
                                                <div class="ctmTableImgWrap">
                                                    <p data-toggle="tooltip" data-placement="top" title="Image">Image</p>
                                                </div>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmCheckWrap">
                                                <div class="ctmTableImgWrap">
                                                    <p data-toggle="tooltip" data-placement="top" title="First Name">First Name</p>
                                                </div>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmCheckWrap">
                                                <div class="ctmTableImgWrap">
                                                    <p data-toggle="tooltip" data-placement="top" title="Last Name">Last Name</p>
                                                </div>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Email">Email</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Phone">Phone</p>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="City">City</p>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Activities">Activities</p>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Action">Action</p>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-if="contactsLoading">
                                    <tr v-for="i in 5" :key="i">
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <label class="ctmTableCheckContainer">
                                                    <vue-skeleton-loader
                                                        type="rect"
                                                        :width="17"
                                                        :height="20"
                                                        animation="fade"
                                                    />
                                                </label>
                                                <div class="editFieldTitle customerNameField">
                                                    <vue-skeleton-loader
                                                        type="circle"
                                                        :width="36"
                                                        :height="36"
                                                        animation="fade"
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td style="position: relative;">
                                            <vue-skeleton-loader
                                                type="rect"
                                                :width="95"
                                                :height="20"
                                                animation="fade"
                                            />
                                        </td>
                                        <td>
                                            <vue-skeleton-loader
                                                type="rect"
                                                :width="102"
                                                :height="20"
                                                animation="fade"
                                            />
                                        </td>
                                        <td>
                                            <vue-skeleton-loader
                                                type="rect"
                                                :width="102"
                                                :height="20"
                                                animation="fade"
                                            />
                                        </td>
                                        <td>
                                            <vue-skeleton-loader
                                                type="rect"
                                                :width="102"
                                                :height="20"
                                                animation="fade"
                                            />
                                        </td>
                                        <td>
                                            <vue-skeleton-loader
                                                type="rect"
                                                :width="102"
                                                :height="20"
                                                animation="fade"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr v-if="contactsList && contactsList.length == 0">
                                        <td colspan="7" style="text-align:center"><p class="mb-0">No Contacts Available</p></td>
                                    </tr>
                                    <tr v-for="(contact, index) in contactsList" :key="index">
                                        
                                        <!-- checkbox block starts here -->
                                        <td @click="previewContact(contact,index,'input',$event)">
                                            <div class="ctmCheckWrap" data-open="true">
                                                <div style="width: 100%;" data-open="true">
                                                    <div class="ctmCheckWrap" data-open="true">
                                                        <label 
                                                            class="ctmTableCheckContainer" 
                                                            data-open="false" 
                                                            @click.prevent=" contact.id != -1 ? checkedItemHandle(contact.id) : '' "
                                                        >
                                                            <input 
                                                                type="checkbox" 
                                                                v-model="checkedItems" 
                                                                :value="contact.id" 
                                                                :disabled="tourIsActive || contact.id == -1 " 
                                                                data-open="false"
                                                            >
                                                            <span class="checkmark" data-open="false"></span>
                                                        </label>
                                                        <div 
                                                            class="ctmDataTableImgBox" 
                                                            @click.prevent="previewContact(contact, index, 'solid', $event)" 
                                                            data-open="true" 
                                                            v-if="contact.id == -1"
                                                        >
                                                            <img 
                                                                :src="contact.addImage ? contact.addImage_src : '/images/customer_noImage.png'" 
                                                                alt=""
                                                                v-if="contact.id == -1"
                                                                data-open="true"
                                                                @error="handleImageError"
                                                            >
                                                            <img
                                                                :src="generateUrl(contact) ? generateUrl(contact) : '/images/customer_noImage.png'"
                                                                alt=""
                                                                data-open="true"
                                                                @error="handleImageError"
                                                                v-else
                                                            >
                                                        </div>
                                                        <div 
                                                            class="ctmDataTableImgBox" 
                                                            @click.prevent="previewContact(contact, index, 'solid', $event)" 
                                                            data-open="true" 
                                                            v-else
                                                        >
                                                            <img 
                                                                :src="contact.addImage ? contact.addImage_src : '/images/customer_noImage.png'" 
                                                                alt=""
                                                                v-if="contact.id == -1"
                                                                data-open="true"
                                                                @error="handleImageError"
                                                            >
                                                            <img
                                                                :src="generateUrl(contact) ? generateUrl(contact) : '/images/customer_noImage.png'"
                                                                alt=""
                                                                data-open="true"
                                                                @error="handleImageError"
                                                                v-else
                                                            >
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <!-- checkbox block ends here -->

                                        <!-- first name block starts here -->
                                        <td @click.prevent="previewContact(contact,index,'input',$event)">
                                            <div class="editFieldTitle customerNameField" data-open="true" id="table-first-name-field">
                                                <input 
                                                    type="text"
                                                    placeholder="Write a first name"
                                                    v-model="contact.first_name" 
                                                    @focus="contactSelection(contact)" 
                                                    @blur="handleOTGUpdate(contact,index)"
                                                    :disabled="tourIsActive"
                                                    data-open="false"
                                                >
                                            </div>
                                        </td>
                                        <!-- first name block ends here -->

                                        <!-- last name block starts here -->
                                        <td @click.prevent="previewContact(contact,index,'input',$event)">
                                            <div class="ctmCheckWrap" data-open="true" id="table-last-name-field">
                                                <div class="editFieldTitle customerNameField" data-open="true">
                                                    <input 
                                                        type="text" 
                                                        placeholder="Write a last name"
                                                        v-model="contact.last_name" 
                                                        @focus="contactSelection(contact)" 
                                                        @blur="handleOTGUpdate(contact,index)"
                                                        :disabled="tourIsActive"
                                                        data-open="false"
                                                    >
                                                </div>
                                            </div>
                                        </td>
                                        <!-- last name block ends here -->

                                        <!-- email block starts here -->
                                        <td @click.prevent="previewContact(contact,index,'solid',$event)">
                                            <div class="tableBodyCtmTxt" data-open="true">
                                                <p data-open="true">{{ contact.email ? contact.email : '--' }}</p>
                                            </div>
                                        </td>
                                        <!-- email block ends here -->

                                        <!-- phone no. block starts here -->
                                        <td @click.prevent="previewContact(contact,index,'solid',$event)">
                                            <div class="tableBodyCtmTxt" data-open="true">
                                                <p data-open="true">{{ contact.mobile_no ? contact.mobile_no : '--' }}</p>
                                            </div>
                                        </td>
                                        <!-- phone no. block ends here -->

                                        <!-- city block starts here -->
                                        <td style="position: relative;" @click.prevent="previewContact(contact,index,'solid',$event)">
                                            <div class="tableBodyCtmTxt" data-open="true">
                                                <p data-open="true">{{ contact.city ? contact.city.name : '--'}}</p>
                                            </div>
                                        </td>
                                        <!-- city block ends here -->

                                        <!-- activities block starts here -->
                                        <td @click="previewContact(contact,index,'input',$event)">
                                            <div class="ctmDataTableActionBtn" data-open="true">
                                                <el-tooltip class="box-item" effect="dark" content="Contact Activities" placement="left">
                                                    <button class="delFunction view" @click.prevent="previewContactActivities(contact,index)" :disabled="tourIsActive" data-open="false">
                                                        <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                                            <defs>
                                                                <path d="M0 0h48v48H0V0z" id="a"/>
                                                            </defs>
                                                            <clipPath id="b">
                                                                <use overflow="visible" xlink:href="#a"/>
                                                            </clipPath>
                                                            <path clip-path="url(#b)" d="M46 16c0 2.2-1.8 4-4 4-.36 0-.7-.04-1.02-.14l-7.12 7.1c.1.32.14.68.14 1.04 0 2.2-1.8 4-4 4s-4-1.8-4-4c0-.36.04-.72.14-1.04l-5.1-5.1c-.32.1-.68.14-1.04.14s-.72-.04-1.04-.14l-9.1 9.12c.1.32.14.66.14 1.02 0 2.2-1.8 4-4 4s-4-1.8-4-4 1.8-4 4-4c.36 0 .7.04 1.02.14l9.12-9.1c-.1-.32-.14-.68-.14-1.04 0-2.2 1.8-4 4-4s4 1.8 4 4c0 .36-.04.72-.14 1.04l5.1 5.1c.32-.1.68-.14 1.04-.14s.72.04 1.04.14l7.1-7.12c-.1-.32-.14-.66-.14-1.02 0-2.2 1.8-4 4-4s4 1.8 4 4z"/>
                                                        </svg>
                                                    </button>
                                                </el-tooltip>
                                            </div>
                                        </td>
                                        <!-- activities block ends here -->

                                        <!-- action block starts here -->
                                        <td @click="previewContact(contact,index,'input',$event)">
                                            <div class="ctmDataTableActionBtn" v-if="isDeletingItem(contact.id) && deleteLoader" data-open="true">
                                                <loaderBtn />
                                            </div>
                                            <div class="ctmDataTableActionBtn" data-open="true" v-else>
                                                <button class="delFunction" @click.prevent="contactDeleteHandle(contact,index)" :disabled="tourIsActive" data-open="false">
                                                    <i aria-hidden="true" class="fa fa-trash-o" data-open="false"></i>
                                                </button>
                                            </div>
                                        </td>
                                        <!-- action block ends here -->

                                    </tr>

                                    <!-- load more block starts here -->
                                    <tr v-if="isPending">
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <label class="ctmTableCheckContainer">
                                                    <vue-skeleton-loader
                                                        type="rect"
                                                        :width="17"
                                                        :height="20"
                                                        animation="fade"
                                                    />
                                                </label>
                                                <div class="editFieldTitle customerNameField">
                                                    <vue-skeleton-loader
                                                        type="circle"
                                                        :width="36"
                                                        :height="36"
                                                        animation="fade"
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td style="position: relative;">
                                            <vue-skeleton-loader
                                                type="rect"
                                                :width="95"
                                                :height="20"
                                                animation="fade"
                                            />
                                        </td>
                                        <td>
                                            <vue-skeleton-loader
                                                type="rect"
                                                :width="102"
                                                :height="20"
                                                animation="fade"
                                            />
                                        </td>
                                        <td>
                                            <vue-skeleton-loader
                                                type="rect"
                                                :width="102"
                                                :height="20"
                                                animation="fade"
                                            />
                                        </td>
                                        <td>
                                            <vue-skeleton-loader
                                                type="rect"
                                                :width="102"
                                                :height="20"
                                                animation="fade"
                                            />
                                        </td>
                                        <td>
                                            <vue-skeleton-loader
                                                type="rect"
                                                :width="102"
                                                :height="20"
                                                animation="fade"
                                            />
                                        </td>
                                    </tr>
                                    <!-- load more block ends here -->

                                </tbody>
                            </table>
                        </div>

                        <!-- add/update contact form starts here -->
                        <div class="productSlideBox" ref="viewInputForm" :class="previewForm.id ? 'activeRow': ''" >
                            <div class="tableActionBtns" id="contact-details-preview">
                                <div class="productTableSideBar">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="viewOrderTitle">
                                                <p>{{ previewForm.first_name + " " + previewForm.last_name }}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <div class="orderViewNav">
                                                <ul>
                                                    <el-tooltip class="box-item" effect="dark" content="Expand" placement="top-start">
                                                        <li class="sm-extand-hide">
                                                            <button @click.prevent="expand" :disabled="tourIsActive">
                                                                <svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg" id="expand-form">
                                                                    <g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" transform="translate(2 2)">
                                                                        <path d="m16.5 5.5v-4.978l-5.5.014"/>
                                                                        <path d="m16.5.522-6 5.907"/>
                                                                        <path d="m11 16.521 5.5.002-.013-5.5"/>
                                                                        <path d="m16.5 16.429-6-5.907"/>
                                                                        <path d="m.5 5.5v-5h5.5"/>
                                                                        <path d="m6.5 6.429-6-5.907"/>
                                                                        <path d="m6 16.516-5.5.007v-5.023"/>
                                                                        <path d="m6.5 10.5-6 6"/>
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                        </li>
                                                    </el-tooltip>
                                                    <el-tooltip class="box-item" effect="dark" content="Close" placement="top-start">
                                                        <li>
                                                            <button @click.prevent="closePreview" id="closeSide" :disabled="tourIsActive">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="29.446" height="33.409" viewBox="0 0 29.446 33.409">
                                                                    <g id="Group_20906" data-name="Group 20906" transform="translate(362.041 -2185.5)">
                                                                        <path id="Path_57522" data-name="Path 57522" d="M-361.041,2186.648v31.409" transform="translate(0 -0.148)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                                                        <g id="Group_20905" data-name="Group 20905" transform="translate(0 -0.5)">
                                                                            <path id="Path_57523" data-name="Path 57523" d="M-354.018,2202.5h20.265" transform="translate(0 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                                                            <path id="Path_57524" data-name="Path 57524" d="M-342.6,2193.747l8,9.15-8,8.85" transform="translate(1 -0.247)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                        </li>
                                                    </el-tooltip>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="sectionTabsLink" style="padding: 15px 20px;">
                                    <ul onclick="window.myFunction(event)">
                                        <li v-if="!tourIsActive">
                                            <a class="active" id="category-detail-navbtn" href="#contactDetails">Contact Info</a>
                                        </li>
                                    </ul>
                                    <div class="updateBtnBox" v-if="contactIsSaving">
                                        <loaderBtn/>
                                    </div>
                                    <div class="updateBtnBox" id="save-contact-btn" v-else>
                                        <button
                                            form="contact-information" 
                                            type="submit" 
                                            :disabled="tourIsActive"
                                        >
                                            {{ previewForm.id == -1 ? "Save" : "Update" }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="productTableInfoBlock">
                                <div class="productTableInfoWhiteBox">

                                    <!-- image for add contact -->
                                    <div class="uploadNewUserImg" v-if="this.previewForm.id == -1">
                                        <div class="userDetailsImg">
                                            <img 
                                                :src="previewForm.addImage ? previewForm.addImage_src ? previewForm.addImage_src : base_image_url + setting.store_id + '/user/' + previewForm.addImage : '/images/customer_noImage.png' " 
                                                alt=""
                                                @error="handleImageError"
                                            />
                                        </div>
                                        <div class="uploadImgFile" id="contact-image">
                                            <input 
                                                type="file" 
                                                ref="addPreviewFileInput"
                                                @change.prevent="changeAddImage" 
                                                accept="image/png,image/jpg,image/jpeg,image/webp" 
                                                :disabled="tourIsActive"
                                            >
                                            <button>
                                                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M20.9984 6.24971C20.9984 4.45495 19.5435 3 17.7487 3H6.24971C4.45495 3 3 4.45495 3 6.24971V17.7487C3 19.5435 4.45495 20.9984 6.24971 20.9984H11.1644L11.5202 19.5754C11.5266 19.5497 11.5333 19.5241 11.5402 19.4985H6.24971C6.04586 19.4985 5.85015 19.4637 5.66822 19.3996L11.4745 13.7138L11.558 13.6431C11.8493 13.4307 12.2588 13.4543 12.5238 13.7139L14.6028 15.7501L15.6636 14.6893L13.5732 12.6423L13.4448 12.5257C12.5666 11.7877 11.2581 11.8265 10.4251 12.6421L4.60312 18.3423C4.53629 18.157 4.49987 17.9571 4.49987 17.7487V6.24971C4.49987 5.2833 5.2833 4.49987 6.24971 4.49987H17.7487C18.7151 4.49987 19.4985 5.2833 19.4985 6.24971V11.2317C19.9774 11.0412 20.492 10.9678 20.9984 11.0115V6.24971ZM17.503 8.75161C17.503 7.50791 16.4947 6.49969 15.251 6.49969C14.0073 6.49969 12.9991 7.50791 12.9991 8.75161C12.9991 9.99531 14.0073 11.0035 15.251 11.0035C16.4947 11.0035 17.503 9.99531 17.503 8.75161ZM14.499 8.75161C14.499 8.33626 14.8357 7.99956 15.251 7.99956C15.6664 7.99956 16.0031 8.33626 16.0031 8.75161C16.0031 9.16695 15.6664 9.50366 15.251 9.50366C14.8357 9.50366 14.499 9.16695 14.499 8.75161ZM19.0984 12.6686L13.1965 18.5705C12.8524 18.9146 12.6083 19.3458 12.4903 19.8179L12.0327 21.6484C11.8336 22.4445 12.5547 23.1656 13.3508 22.9666L15.1813 22.5089C15.6534 22.3909 16.0846 22.1468 16.4287 21.8027L22.3306 15.9008C23.2231 15.0082 23.2231 13.5611 22.3306 12.6686C21.4381 11.7761 19.991 11.7761 19.0984 12.6686Z"/>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>

                                    <!-- image for update contact -->
                                    <div class="uploadNewUserImg" v-if="this.previewForm.id != -1">
                                        <div class="userDetailsImg">
                                            <img 
                                                :src="previewForm.image_src" 
                                                alt="" 
                                                v-if="previewForm.image"
                                                @error="handleImageError"
                                            />
                                            <img 
                                                :src="previewForm.is_own_image == 0 ? previewForm.image_src ? base_image_url + settings.store_id + '/user/' + previewForm.image_src : '/images/customer_noImage.png' : previewForm.image_src ? base_image_url + settings.store_id + '/crm-contact/' + previewForm.image_src : '/images/customer_noImage.png' " 
                                                alt=""
                                                @error="handleImageError" 
                                                v-else
                                            />
                                        </div>
                                        <div class="uploadImgFile" id="contact-image">
                                            <input 
                                                type="file" 
                                                ref="previewFileInput" 
                                                @change.prevent="handleChangeEditImage" 
                                                accept="image/png,image/jpg,image/jpeg,image/webp" 
                                                :disabled="tourIsActive"
                                            >
                                            <button>
                                                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M20.9984 6.24971C20.9984 4.45495 19.5435 3 17.7487 3H6.24971C4.45495 3 3 4.45495 3 6.24971V17.7487C3 19.5435 4.45495 20.9984 6.24971 20.9984H11.1644L11.5202 19.5754C11.5266 19.5497 11.5333 19.5241 11.5402 19.4985H6.24971C6.04586 19.4985 5.85015 19.4637 5.66822 19.3996L11.4745 13.7138L11.558 13.6431C11.8493 13.4307 12.2588 13.4543 12.5238 13.7139L14.6028 15.7501L15.6636 14.6893L13.5732 12.6423L13.4448 12.5257C12.5666 11.7877 11.2581 11.8265 10.4251 12.6421L4.60312 18.3423C4.53629 18.157 4.49987 17.9571 4.49987 17.7487V6.24971C4.49987 5.2833 5.2833 4.49987 6.24971 4.49987H17.7487C18.7151 4.49987 19.4985 5.2833 19.4985 6.24971V11.2317C19.9774 11.0412 20.492 10.9678 20.9984 11.0115V6.24971ZM17.503 8.75161C17.503 7.50791 16.4947 6.49969 15.251 6.49969C14.0073 6.49969 12.9991 7.50791 12.9991 8.75161C12.9991 9.99531 14.0073 11.0035 15.251 11.0035C16.4947 11.0035 17.503 9.99531 17.503 8.75161ZM14.499 8.75161C14.499 8.33626 14.8357 7.99956 15.251 7.99956C15.6664 7.99956 16.0031 8.33626 16.0031 8.75161C16.0031 9.16695 15.6664 9.50366 15.251 9.50366C14.8357 9.50366 14.499 9.16695 14.499 8.75161ZM19.0984 12.6686L13.1965 18.5705C12.8524 18.9146 12.6083 19.3458 12.4903 19.8179L12.0327 21.6484C11.8336 22.4445 12.5547 23.1656 13.3508 22.9666L15.1813 22.5089C15.6534 22.3909 16.0846 22.1468 16.4287 21.8027L22.3306 15.9008C23.2231 15.0082 23.2231 13.5611 22.3306 12.6686C21.4381 11.7761 19.991 11.7761 19.0984 12.6686Z"/>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>

                                    <ValidationObserver ref="contactObserver">
                                        <form id="contact-information" @submit.prevent="validate('preview')">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <ValidationProvider name="first name" :rules="{required:true,max:50}" v-slot="{ errors }">
                                                        <div class="tableFromFieldBox">
                                                            <label for="first-name-field-1">First Name<span>*</span></label>
                                                            <div class="tableFromFieldItem">
                                                                <input 
                                                                    type="text" 
                                                                    name="first-name-field-1" 
                                                                    id="first-name-field-1" 
                                                                    placeholder="First name" 
                                                                    v-model="previewForm.first_name" 
                                                                    @input="handleFormat('update','f_name')"
                                                                    :disabled="tourIsActive"
                                                                >
                                                                <span class="text-danger" >{{ errors[0] }}</span>
                                                            </div>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-md-6">
                                                    <ValidationProvider name="last name" :rules="{required:true,max:50}" v-slot="{ errors }">
                                                        <div class="tableFromFieldBox">
                                                            <label for="last-name-field-1">Last Name<span>*</span></label>
                                                            <div class="tableFromFieldItem">
                                                                <input 
                                                                    type="text" 
                                                                    name="last-name-field-1" 
                                                                    id="last-name-field-1" 
                                                                    placeholder="Last name" 
                                                                    v-model="previewForm.last_name" 
                                                                    @input="handleFormat('update','l_name')"
                                                                    :disabled="tourIsActive"
                                                                >
                                                                <span class="text-danger" >{{ errors[0] }}</span>
                                                            </div>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-md-6">
                                                    <ValidationProvider name="email" rules="required|email|max:250" v-slot="{ errors }">
                                                        <div class="tableFromFieldBox">
                                                            <label for="email-field">Email<span>*</span></label>
                                                            <div class="tableFromFieldItem">
                                                                <input 
                                                                    type="email" 
                                                                    name="email-field" 
                                                                    id="email-field" 
                                                                    placeholder="Email" 
                                                                    v-model="previewForm.email"
                                                                    :disabled="previewForm.id != -1 || tourIsActive"
                                                                >
                                                                <span class="text-danger" >{{ errors[0] }}</span>
                                                            </div>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-md-6" id="mobile-no-field">
                                                    <div class="tableFromFieldBox">
                                                        <label for="mNumber">Mobile No.</label>
                                                        <div class="tableFromFieldItem">
                                                            <vue-tel-input 
                                                                v-model="previewForm.mobile_no"
                                                                mode="international"
                                                                :onlyCountries="allowedCountries"
                                                                :inputOptions="contactFieldOptions"
                                                                ref="contactNoField"
                                                                :dropdownOptions="contactFieldDropDownOptions"
                                                                :validCharactersOnly=true 
                                                                @input="contactInputHandle"
                                                                @validate="validateContactNo"
                                                                @country-changed="countryChangeHandle"
                                                                @focus="contactFieldFocusHandle"
                                                                @open="dropDownOpenHandle"
                                                                @close="dropDownCloseHandle"
                                                                :disabled="tourIsActive"
                                                            ></vue-tel-input>
                                                            <span class="text-danger" v-if="previewForm.mobile_no.length > 0 && !countryIsSelected && contactFieldTouched">
                                                                Select a country from the dropdown menu or add the country code before the mobile no.
                                                            </span>
                                                            <span class="text-danger" v-else-if="previewForm.mobile_no.length > 0 && !contactIsValid && contactFieldTouched">
                                                                Enter a valid mobile no.
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6" id="country-field">
                                                    <div class="tableFromFieldBox">
                                                        <label for="country">Country</label>
                                                        <div class="vueSelectBlock">
                                                            <Multiselect
                                                                v-model="selectedCountry"
                                                                id="country"
                                                                :clearOnSelect="true"
                                                                :options="countriesList"
                                                                label="name"
                                                                :multiple="false"
                                                                :object="true"
                                                                track-by="name"
                                                                :hideSelected="false"
                                                                :showNoResults="true"
                                                                placeholder="Select Country"
                                                                :showOptions="true"
                                                                :searchable="true"
                                                                openDirection="bottom"
                                                                :disabled="tourIsActive"
                                                            >
                                                                <span slot="noResult">No country found matching the searched keyword.</span>
                                                            </Multiselect>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6" id="state-field">
                                                    <div class="tableFromFieldBox">
                                                        <label for="state">State</label>
                                                        <div class="vueSelectBlock">
                                                            <Multiselect
                                                                v-model="selectedState"
                                                                id="state"
                                                                :clearOnSelect="true"
                                                                :options="listStates ? listStates : []"
                                                                label="name"
                                                                track-by="name"
                                                                :hideSelected="false"
                                                                :showNoResults="true"
                                                                :multiple="false"
                                                                :object="false"
                                                                :showOptions="false"
                                                                :searchable="true"
                                                                :loading="statesLoading"
                                                                openDirection="bottom"
                                                                :placeholder="statesLoading ? 'Loading States....' : 'Select State'"
                                                                :disabled="tourIsActive"
                                                            >
                                                                <span slot="noOptions">To load the states, please select a country.</span>
                                                                <span slot="noResult">No state found matching the searched keyword.</span>
                                                            </Multiselect>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6" id="city-field">
                                                    <div class="tableFromFieldBox">
                                                        <label for="city">City</label>
                                                        <div class="vueSelectBlock">
                                                            <Multiselect
                                                                v-model="selectedCity"
                                                                id="city"
                                                                :clearOnSelect="true"
                                                                :options="listCities ? listCities : []"
                                                                label="name"
                                                                track-by="name"
                                                                :hideSelected="false"
                                                                :showNoResults="true"
                                                                :multiple="false"
                                                                :object="true"
                                                                :showOptions="true"
                                                                :searchable="true"
                                                                :loading="citiesLoading"
                                                                openDirection="bottom"
                                                                :placeholder="citiesLoading ? 'Loading Cities....' : 'Select City'"
                                                                :disabled="tourIsActive"
                                                            >
                                                                <span slot="noOptions">To load the cities, please select a state.</span>
                                                                <span slot="noResult">No city found matching the searched keyword.</span>
                                                            </Multiselect>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="tableFromFieldBox">
                                                        <ValidationProvider name="address" rules="max:500" v-slot="{ errors }">
                                                            <label for="address-field">Address</label>
                                                            <div class="tableFromFieldItem">
                                                                <textarea 
                                                                    name="address-field" 
                                                                    id="address-field" 
                                                                    placeholder="Address" 
                                                                    v-model="previewForm.address"
                                                                    :disabled="tourIsActive"
                                                                >
                                                                </textarea>
                                                            </div>
                                                            <span class="text-danger" >{{ errors[0] }}</span>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </ValidationObserver>
                                </div>
                            </div>
                        </div>
                        <!-- add/update contact form ends here -->

                    </div>
                    <!-- data table ends here -->

                    <!-- bottom action bar starts here-->
                    <div class="row product-bottom-bar m-0" v-if="checkedItems.length > 1">
                        <div class="col-md-6 model-footer-main1">
                            <div class="bottom-bar">
                                <a href="" class="model-footer-1 mr-5" @click.prevent="toggleIsCheckedAll()" v-if="checkedItems.length != contactsList.length">
                                    <i class="fa fa-check-square-o" aria-hidden="true"></i>
                                    <p>SELECT ALL</p>
                                </a>
                                <a href="" class="model-footer-1" @click.prevent="clearSelection()">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                                        <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                                    </svg>
                                    <p>CLEAR SELECTION</p>
                                </a>
                            </div>
                            <div class="model-footer-2">{{ checkedItems.length }}</div>
                        </div>
                        <div class="col-md-6 model-footer-main1 product-row-reverse">
                            <div class="bottom-bar">
                                <a href="" class="model-footer-1 ml-4" @click.prevent="deleteBulkContacts()">
                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                    <p>DELETE</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <!-- bottom action bar ends here-->

                </div>
            </div>

            
            <!-- contact activity details modal starts here -->
            <div class="modalBg categoryResponsiveModal " :class="contactActivitiesModal.isOpen ? 'modal-active' : ''" >
                <div class="modalLayers" @click="contactActivitiesModal.isOpen = false"></div>
                <div class="modalBody">
                    <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%" v-if="emailDetailsSection.isOpen">
                        <div id="London" class="tabcontent">
                            <div class="contaient-div horizontal-scroll-fix">
                                <div class="customer-detail customer-detail-product contacts groupedContactsHeader">
                                    <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" @click="closeEmailDetailsSection()">
                                        <title/>
                                        <g data-name="Layer 2" id="Layer_2">
                                            <path d="M31,16A15,15,0,1,1,16,1,15,15,0,0,1,31,16ZM3,16A13,13,0,1,0,16,3,13,13,0,0,0,3,16Z"/>
                                            <path d="M19.87,10.41,14.29,16l5.58,5.59a1,1,0,0,1,0,1.41h0a1,1,0,0,1-1.41,0L12.1,16.64a.91.91,0,0,1,0-1.28L18.46,9a1,1,0,0,1,1.41,0h0A1,1,0,0,1,19.87,10.41Z"/>
                                        </g>
                                    </svg>
                                    <h2>Email Details</h2>
                                </div>
                            </div>
                            <div class="content-table" v-if="emailDetailsSection.pending">
                                <vue-skeleton-loader
                                    type="rect"
                                    :width="1070"
                                    :height="300"
                                    animation="fade"
                                />
                            </div>
                            <div class="content-table" v-html="emailDetailsSection.template_html" v-else>

                            </div>
                        </div>
                    </div>
                    <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%" v-else>
                        <div class="row" v-if="contactActivitiesModal.isOpen && contactActivitiesModal.activities.length == 0">
                            <div class="col-md-6">
                                <div class="notDataWrap">
                                    <p>
                                        There are no activities associated with this contact.
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-6 mt-3">
                                <div class="assign-btn">
                                    <button type="submit" class="product-btn" @click="openContactActivityForm('add',null)">
                                        Add Activity
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-else>
                            <div class="col-md-12 mt-3">
                                <div class="assign-btn">
                                    <button type="submit" class="product-btn" @click="openContactActivityForm('add',null)">
                                        Add Activity
                                    </button>
                                </div>
                            </div>
                        </div>
                        <el-timeline v-if="contactActivitiesModal.activities.length > 0">
                            <el-timeline-item 
                                :timestamp="
                                    activity.created_at
                                    ? new Date(activity.created_at).toLocaleString('en-US', {
                                        day: 'numeric',
                                    }) +
                                    '-' +
                                    new Date(activity.created_at).toLocaleString('en-US', {
                                        month: 'numeric',
                                    }) +
                                    '-' +
                                    new Date(activity.created_at).toLocaleString('en-US', {
                                        year: 'numeric',
                                    })
                                        +
                                    ' | ' +
                                    new Date(activity.created_at).toLocaleTimeString('en-US')
                                    : '--'
                                " 
                                placement="top" 
                                v-for="(activity,index) in contactActivitiesModal.activities" :key="index"
                            >
                                <el-card>
                                    <div class="bankPaymentHeader activity">
                                        <h3>{{ activity.title }}</h3>
                                        <div class="bankPaymentOptions">
                                            <button type="button" class="delPaymentField" @click="openContactActivityForm('edit',activity)">
                                                <svg class="feather feather-edit" fill="none" height="24" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"/>
                                                    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"/>
                                                </svg>
                                            </button>
                                            <button type="button" class="delPaymentField" disabled v-if="isDeletingActivity(activity.id)">
                                                <div class="item loading-6">
                                                    <svg viewBox="25 25 50 50">
                                                        <circle cx="50" cy="50" r="20"></circle>
                                                    </svg>
                                                </div>
                                            </button>
                                            <button type="button" class="delPaymentField" @click="removeContactActivityHandle(activity,index)" v-else>
                                                <svg enable-background="new 0 0 32 32" id="Glyph" version="1.1" viewBox="0 0 32 32" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                                    <path d="M6,12v15c0,1.654,1.346,3,3,3h14c1.654,0,3-1.346,3-3V12H6z M12,25c0,0.552-0.448,1-1,1s-1-0.448-1-1v-9  c0-0.552,0.448-1,1-1s1,0.448,1,1V25z M17,25c0,0.552-0.448,1-1,1s-1-0.448-1-1v-9c0-0.552,0.448-1,1-1s1,0.448,1,1V25z M22,25  c0,0.552-0.448,1-1,1s-1-0.448-1-1v-9c0-0.552,0.448-1,1-1s1,0.448,1,1V25z" id="XMLID_237_"/>
                                                    <path d="M27,6h-6V5c0-1.654-1.346-3-3-3h-4c-1.654,0-3,1.346-3,3v1H5C3.897,6,3,6.897,3,8v1c0,0.552,0.448,1,1,1h24  c0.552,0,1-0.448,1-1V8C29,6.897,28.103,6,27,6z M13,5c0-0.551,0.449-1,1-1h4c0.551,0,1,0.449,1,1v1h-6V5z" id="XMLID_243_"/>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div v-html="activity.description">

                                    </div>
                                </el-card>
                            </el-timeline-item>
                        </el-timeline>
                    </div>
                    <div class="modalCloseIcon">
                        <button @click="contactActivitiesModal.isOpen = false">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                                <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <!-- contact activity details modal ends here -->

            <!-- add/update contact activity modal starts here -->
            <ValidationObserver ref="contactActivityObserver">
                <div class="modalBg" :class="contactActivityForm.isOpen ? 'modal-active': ''">
                    <div class="modalLayers" @click.prevent="closeContactActivityModal()"></div>
                    <div class="modalBody" id="add-vendor-modal">
                        <div class="oderSlideBox productSideBox" style="position:relative;top:15px;right:0;width:100%">
                            <div id="London" class="tabcontent">
                                <div class="contaient-div horizontal-scroll-fix">
                                    <div class="titleActiveBox">
                                        <div class="customer-detail customer-detail-product pt-0">
                                            <h2>ACTIVITY DETAILS</h2>
                                        </div>
                                    </div>
                                    <form class="categoryFormBlock" @submit.prevent="validate('contactActivity')">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="formContentBox" style="position: relative">
                                                    <label for="addActivityTitle">Title <span> *</span></label>
                                                    <ValidationProvider name="title" rules="required|max:250" v-slot="{ errors }">
                                                        <input
                                                            type="text"
                                                            name="addActivityTitle"
                                                            id="addActivityTitle"
                                                            v-model="contactActivityForm.title"
                                                            placeholder="Title"
                                                        />
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="formContentBox" style="position: relative">
                                                    <label for="addActivityTitle">Description <span> *</span></label>
                                                    <ValidationProvider name="description" rules="required|max:500" v-slot="{ errors }">
                                                        <textarea
                                                            class="form-control foam-border comment-box mt-0"
                                                            placeholder="Description"
                                                            rows="3"
                                                            cols="30"
                                                            name="comment"
                                                            v-model="contactActivityForm.description"
                                                        ></textarea>
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <ValidationProvider name="type" rules="required" v-slot="{ errors }">
                                                    <div class="tableFromFieldBox" style="position: relative;">
                                                        <label for="addActivityType">Type <span> *</span></label>
                                                        <div class="vueSelectBlock">
                                                            <multiselect
                                                                v-model="contactActivityForm.type"
                                                                id="addActivityType"
                                                                tag-placeholder="Add this as a new activity type"
                                                                placeholder="Add Activity Type"
                                                                :options="activityTypesList"
                                                                :taggable="true"
                                                                @tag="addActivityType"
                                                            ></multiselect>
                                                        </div>
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col-md-12 mt-3">
                                                <div class="assign-btn">
                                                    <loaderBtn class="product-btn" v-if="contactActivityForm.pending"/>
                                                    <button type="submit" class="product-btn" v-else>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="modalCloseIcon">
                            <button @click.prevent="closeContactActivityModal()" :disabled="tourIsActive">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                                    <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </ValidationObserver>
            <!-- add/update contact activity modal ends here -->

        </div>

        <!--vue tour-->
        <v-tour name="contactsTour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>

    </div>
</template>

<script>
import subHeader from "@/components/subHeader.vue";
import moduleStats from "@/components/moduleStats.vue";
import tutorialsAction from "@/components/tutorialsAction.vue";
import loaderBtn from "@/components/loaderBtn.vue";
import _ from 'lodash';
import { mapGetters } from 'vuex';
import {MessageBox, Message, Loading} from 'element-ui'
import Multiselect from "vue-multiselect";
import moduleSearch from "@/components/ModuleSearch/index.vue";

export default {
    name:'Contacts',
    metaInfo() {
        return {
            title: 'CRM | Contacts | YeetCommerce',
        };
    },
    data() {
        return {
            base_image_url: process.env.VUE_APP_STORE_IMAGE_BASE_URL,
            
            //contacts start
            previewForm:{
                id: '',
                isPreview: false,
                first_name: '',
                last_name: '',
                email: '',
                mobile_no: '',
                address: '',
                image: '',
                image_src: '',
                addImage: null,
                addImage_src: '',
                city: null,
                isLoading: false,
                isActive: false,
                isTouched: false,
            },
            tempContact:{
                id: '',
                isPreview: false,
                first_name: '',
                last_name: '',
                email: '',
                mobile_no: '',
                address: '',
                image: '',
                image_src: '',
                addImage: null,
                addImage_src: '',
                city: null,
                isLoading: false,
                isActive: false,
                isTouched: false,
            },
            tempPreviewContact:{
                id: '',
                isPreview: false,
                first_name: '',
                last_name: '',
                email: '',
                mobile_no: '',
                address: '',
                image: '',
                image_src: '',
                addImage: null,
                addImage_src: '',
                city: null,
                isLoading: false,
                isActive: false,
                isTouched: false,
            },
            contactsLoading: true,
            contactsList: [],
            isContactSaved: false,
            contactIsSaving: false,
            //contacts end

            checkedItems: [],
            deletingItems: [],
            deleteLoader: false,
            isMasterSearched: false,

            //country, state, city start
            countriesList: [],
            selectedCountry: null,
            listStates: [],
            selectedState: null,
            listCities: [],
            selectedCity: null,
            statesLoading: false,
            citiesLoading: false,
            //country, state, city end

            //image validations start
            imageValidations:{
                imgIsRequired: false,
                imgInvalidFormat: false,
                isNotImg: false,
            },
            //image validations end

            //contact field handle
            allowedCountries:[],
            contactFieldOptions:{
                placeholder: "Mobile no.",
                showDialCode: true,
                type: 'tel',
                maxlength: 25,
            },
            contactFieldDropDownOptions:{
                showSearchBox: true,
                showDialCodeInSelection: false,
                showDialCodeInList: true,
                showFlags: true,
                placeholder: "Select a Country"
            },
            contactIsValid: true,
            countryIsSelected: false,
            contactFieldTouched: false,
            selectedContactObj: {},
            selectedContactCountry: [],

            //contact activity start
            contactActivitiesModal:{
                contact: null,
                activities: [],
                isOpen: false,
            },
            contactActivityForm:{
                contact_id: '',
                activity_id: '',
                title: '',
                description: '',
                type: '',
                isOpen: false,
                pending: false,
                isEdit: false,
            },
            deletingActivity: [],
            activityIsRemoving: false,
            activityTypesList: [],
            //contact activity end

            //email details start
            emailDetailsSection:{
                template_html: '',
                isOpen: false,
                pending: false,
            },
            //email details end

            //vue-tour
            tourOptions: {
                useKeyboardNavigation: false,
                labels: {
                    buttonSkip: 'Skip Tour',
                    buttonPrevious: 'Previous',
                    buttonNext: 'Next',
                    buttonStop: 'Finish Tour'
                }
            },
            tourCallbacks: {
                onPreviousStep: this.handlePreviousStep,
                onNextStep: this.handleNextStep,
                // onStart: this.handleTourStart,
                onStop: this.handleTourStop,
                onFinish: this.handleTourFinish,
                onSkip: this.handleTourSkip
            },
            tourSteps:[
                //header steps
                {
                    target: '#tour-start-btn',
                    content: `<strong>Start Tour</strong><br>Provides insights into the available functionalities within a specific menu, helping you understand what actions you can perform.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#master-search',
                    content: `<strong>Master Search</strong><br>Enables you to perform searches from all over the dashboard.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    },
                },
                {
                    target: '#add-new-dropDown',
                    content: `<strong>Add New Record</strong><br>Enables you to add new orders, products and categories on the go.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#help-link',
                    content: `<strong>Help</strong><br>If you require any assistance, feel free to reach out to us.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#user-options-dropdown',
                    content: `<strong>User Information</strong><br>Presents essential details about you along with a selection of available options.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                //search bar step
                {
                    target: '#module-search',
                    content: `<strong>Search Contact</strong><br>It enables you to search specific contacts.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'bottom'
                    }
                },
                //form steps
                {
                    target: '#preview-record',
                    content: `<strong>Contacts Table</strong><br>You can preview and edit the added contacts by clicking on each individual record present in the table.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'top'
                    }
                },
                {
                    target: '#add-new-record',
                    content: `Add a <strong>Contact</strong><br>By clicking this button, you can add a new contact and open a form to input and save the details.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#contact-details-preview',
                    content: `<strong>Contact Details Form</strong><br>This form can be used to add details about the contact and save them.`,
                    params: {
                        highlight: false,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#contact-image',
                    content: `<strong>Contact's Image</strong><br>Image of a contact can be added using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#first-name-field-1',
                    content: `<strong>Contact's First Name</strong><br>First name of the contact can be added using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#last-name-field-1',
                    content: `<strong>Contact's Last Name</strong><br>Last name of the contact can be added using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#email-field',
                    content: `<strong>Contact's Email Address</strong><br>Email address of the contact can be added using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#mobile-no-field',
                    content: `<strong>Contact's Mobile No.</strong><br>Mobile no. of the contact can be added using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#country-field',
                    content: `<strong>Contact's Country</strong><br>Country of the contact can be added using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#state-field',
                    content: `<strong>Contact's State</strong><br>State of the contact can be added using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#city-field',
                    content: `<strong>Contact's City</strong><br>City of the contact can be added using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#address-field',
                    content: `<strong>Contact's Address</strong><br>Address of the contact can be added using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#table-first-name-field',
                    content: `<strong>Contact's First Name</strong><br>First name of the contact can also be added/updated using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'right'
                    }
                },
                {
                    target: '#table-last-name-field',
                    content: `<strong>Contact's Last Name</strong><br>Last name of the contact can also be added/updated using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'left'
                    }
                },
                {
                    target: '#expand-form',
                    content: `<strong>Expand Form</strong><br>Form can be expanded or collapsed by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#closeSide',
                    content: `<strong>Close Form</strong><br>Form can be closed using this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#save-contact-btn',
                    content: `<strong>Save Contact Details</strong><br>Added contact details can be saved by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
            ],
            tourIsActive: false,
        }  
    },
    components:{
        subHeader,
        tutorialsAction,
        moduleStats,
        loaderBtn,
        Multiselect,
        moduleSearch
    },
    computed:{
        ...mapGetters({
            settings: 'settings_module/settings',
            storedContacts: 'contacts_module/contacts',
            contactsCurrentPage: 'contacts_module/currentPage',
            contactsLastPage: 'contacts_module/lastPage',
            contactsSearchKeyword:'contacts_module/keyword',
            isPending: 'contacts_module/isPending',
            storeAllCountries: 'countries_module/allCountries',
            //activityTypes
            storedActivityTypes:'contacts_module/activityTypes'
        }),
    },
    watch:{
        'storedContacts':{
            handler:function(value){
                
                this.contactsLoading = false;

                if(this.previewForm.id == -1){

                    this.contactsList = _.cloneDeep(value)

                    this.contactsList.unshift(this.previewForm)

                }else if (this.tempPreviewContact.id != ''){

                    this.contactsList = _.cloneDeep(value)

                    this.contactsList[this.previewForm.index] = this.previewForm

                }else{

                    this.contactsList = _.cloneDeep(value)

                }


                if(this.isMasterSearched){

                    this.previewSearchedContact(this.contactsList[0],0)

                    this.isMasterSearched = false
                }

            },deep:true
        },
        'previewForm':{
            handler:function(value){

                if((value.id == -1) && (value.first_name || value.last_name || value.email || 
                value.mobile_no || value.address || value.addImage || 
                value.addImage_src)){

                    value.isTouched = true

                }else{

                    value.isTouched = false

                }

            },
            deep:true
        },
        'storedActivityTypes':{
            handler:function(value){

                this.activityTypesList = _.cloneDeep(value)

            },deep:true
        },
        selectedCountry:{
            handler:function(value){

                if(value){

                    this.listStates = [];
                    this.selectedState = null;

                    this.loadStates();

                }else{

                    this.listStates = [];
                    this.selectedState = null;

                }

            },deep:true
        },
        selectedState:{
            handler:function(value){

                if(value){

                    this.listCities = [];
                    this.selectedCity = null;
                    
                    this.loadCities();

                }else{

                    this.listCities = [];
                    this.selectedCity = null;

                }

            },deep:true
        },
        selectedCity:{
            handler:function(value){

                if(value){

                    this.previewContact.city = value

                }else{

                    this.previewContact.city = null

                }

            },deep:true
        },
        storeAllCountries:{
            handler:function(value){

                this.countriesList = value
                
                value.forEach((country)=>{
                    this.allowedCountries.push(country.iso2)
                });

            },deep:true
        },
    },
    methods:{

        handleScrollEvent(event){
            
            let element = this.$refs.contactsTable;
            
            let maxScrollTop = element.scrollHeight - element.clientHeight;

            if(element.scrollTop >= maxScrollTop - 100){

                if(this.isPending == false && this.contactsCurrentPage != this.contactsLastPage){

                    this.$store.dispatch('contacts_module/fetchStoreContactsRequest',{size:15 , page: this.contactsCurrentPage + 1, loadData:'more'});

                }

            }

        },
        handleFormat(form,field){
      
            if(form == 'update' && field == 'f_name'){

                let fname = this.previewForm.first_name;
                fname = fname.replace('^[a-zA-Z0-9\s\-\.\,\_\&\!\#\@\$\%\^\*\(\)\+\=\{\}\[\]\|\\\/\?\:\;\"\'\<\>\~\`]*$', '');
                fname = fname.replace(/\s+/g, ' ');
                fname = fname.replace(/^\w/, (c) => c.toUpperCase());
                
                this.previewForm.first_name = fname

            }else if(form == 'update' && field == 'l_name'){

                let lname = this.previewForm.last_name;
                lname = lname.replace('^[a-zA-Z0-9\s\-\.\,\_\&\!\#\@\$\%\^\*\(\)\+\=\{\}\[\]\|\\\/\?\:\;\"\'\<\>\~\`]*$', '');
                lname = lname.replace(/\s+/g, ' ');
                lname = lname.replace(/^\w/, (c) => c.toUpperCase());

                this.previewForm.last_name = lname
            }

        },
        generateUrl(contact){

            if(contact.image){

                if(contact.is_own_image == 0){

                    if(typeof(contact.image) == "string"){

                        return this.base_image_url + this.settings.store_id + '/user/' + contact.image;

                    }else{

                        return contact.image_src;

                    }

                }else{

                    if(typeof(contact.image) == "string"){

                        return this.base_image_url + this.settings.store_id + '/crm-contact/' + contact.image;

                    }else{

                        return contact.image_src;

                    }

                }

            }else if(contact.image_src){

                if(contact.is_own_image == 1){
                    
                    return this.base_image_url + this.settings.store_id + '/crm-contact/' + contact.image_src;
                    
                }else{

                    return this.base_image_url + this.settings.store_id + '/user/' + contact.image_src;

                }

            }

        },
        contactSelection(contact){

            this.tempContact = _.cloneDeep(contact);

        },
        async handleOTGUpdate(contact,i){

            if(this.previewForm.id == ''){

                if(contact.id != -1){

                    if((this.tempContact.first_name != contact.first_name) || (this.tempContact.last_name != contact.last_name)){

                        let isRequired = false;
                
                        if(!contact.first_name || contact.first_name.length == 0){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The first name field is required.",
                            })

                            isRequired = true

                        }
                        if(contact.first_name && contact.first_name.length > 50){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The first name field may not be greater than 50 characters.",
                            })

                            isRequired = true

                        }


                        if(!contact.last_name || contact.last_name.length == 0){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The last name field is required.",
                            })

                            isRequired = true

                        }
                        if(contact.last_name && contact.last_name.length > 50){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The last name field may not be greater than 50 characters.",
                            })

                            isRequired = true

                        }

                        if(isRequired){

                            // if(!this.contactsList[i].first_name || this.contactsList[i].first_name.length == 0 || this.contactsList[i].first_name.length > 50){

                                this.contactsList[i].first_name = this.tempContact.first_name;

                            // }

                            // if(!this.contactsList[i].last_name || this.contactsList[i].last_name.length == 0 || this.contactsList[i].last_name.length > 50){

                                this.contactsList[i].last_name = this.tempContact.last_name;

                            // }

                            this.tempContact = {
                                id: '',
                                isPreview: false,
                                first_name: '',
                                last_name: '',
                                email: '',
                                mobile_no: '',
                                address: '',
                                city:null,
                                isLoading: false,
                                isActive: false,
                                isTouched:false,
                            }

                            return

                        }

                        MessageBox.confirm(
                        'You have unsaved changes, save and proceed?',
                        'Confirm',
                        {
                            distinguishCancelAndClose: true,
                            confirmButtonText: 'Save',
                            cancelButtonText: 'Discard Changes',
                        }
                        ).then(async () => {

                            let formData = new FormData();

                            formData.append('contact_id',contact.id);
                            formData.append('first_name',contact.first_name.replace(/^\w/, (c) => c.toUpperCase()).replace(/\s+/g,' ').trim());
                            formData.append('last_name',contact.last_name.replace(/^\w/, (c) => c.toUpperCase()).replace(/\s+/g,' ').trim());
                            formData.append('email',contact.email);
                            formData.append('isActive',contact.isActive ? 1 : 0);

                            if(contact.mobile_no){
                                formData.append('mobile_no',contact.mobile_no);
                            }

                            if(contact.address){
                                formData.append('address',contact.address);
                            }

                            if(contact.city){
                                formData.append('city_id',contact.city_id);
                            }

                            let loader = Loading.service({
                                text: "The contact is being updated. Please wait!",
                                fullscreen: true,
                            });

                            try{
                                let res = await this.$axios.post('/crm/contact/update',formData);
                                if(res.data.status_code == "1462"){
                                    
                                    this.$notify({
                                        type: 'success',
                                        title: 'Success',
                                        message: res.data.message,
                                    })

                                    let contact = res.data.contact
                                    contact.isActive = contact.isActive ? true : false;

                                    let payload = {
                                        index: i,
                                        updatedContact: contact
                                    }

                                    this.$store.commit('contacts_module/update_contacts_list',payload);

                                    this.contactsList[i] = _.cloneDeep(contact)

                                    this.tempContact = {
                                        id: '',
                                        isPreview: false,
                                        first_name: '',
                                        last_name: '',
                                        email: '',
                                        mobile_no: '',
                                        address: '',
                                        city:null,
                                        isLoading: false,
                                        isActive: false,
                                        isTouched:false,
                                    };

                                }
                            }catch(error){

                                this.contactsList[i].first_name = this.tempContact.first_name;
                                this.contactsList[i].last_name = this.tempContact.last_name;

                                this.tempContact = {
                                    id: '',
                                    isPreview: false,
                                    first_name: '',
                                    last_name: '',
                                    email: '',
                                    mobile_no: '',
                                    address: '',
                                    city:null,
                                    isLoading: false,
                                    isActive: false,
                                    isTouched:false,
                                }

                                if(error.response){

                                    if(error.response.data.error.contact_id){

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            message: error.response.data.error.contact_id[0],
                                        });

                                    }else{

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            message: error.response.data.message,
                                        });

                                    }

                                }else{

                                    this.$message({
                                        type: 'error',
                                        showClose: true,
                                        message: error.message,
                                    });

                                }

                            }finally{

                                loader.close();

                            }

                        }).catch((error)=>{

                            this.contactsList[i].first_name = this.tempContact.first_name;
                            this.contactsList[i].last_name = this.tempContact.last_name;

                            this.tempContact = {
                                id: '',
                                isPreview: false,
                                first_name: '',
                                last_name: '',
                                email: '',
                                mobile_no: '',
                                address: '',
                                city:null,
                                isLoading: false,
                                isActive: false,
                                isTouched:false,
                            }

                            this.$notify({
                                type: "info",
                                title: "Changes Discarded",
                                message: "Changes discarded successfully.",
                            });

                        });

                    }

                }

            }else{

                if(contact.id != -1){

                    if(contact.id != this.previewForm.id){

                        if((this.tempContact.first_name != contact.first_name) || (this.tempContact.last_name != contact.last_name)){
                            
                            let isRequired = false;
                
                            if(!contact.first_name ||  contact.first_name.length == 0){

                                this.$notify({
                                    type: 'warning',
                                    title: 'Alert',
                                    message: "The first name field is required.",
                                })

                                isRequired = true

                            }
                            if(contact.first_name && contact.first_name.length > 50){

                                this.$notify({
                                    type: 'warning',
                                    title: 'Alert',
                                    message: "The first name field may not be greater than 50 characters.",
                                })

                                isRequired = true

                            }


                            if(!contact.last_name || contact.last_name.length == 0){

                                this.$notify({
                                    type: 'warning',
                                    title: 'Alert',
                                    message: "The last name field is required.",
                                })

                                isRequired = true

                            }
                            if(contact.last_name && contact.last_name.length > 50){

                                this.$notify({
                                    type: 'warning',
                                    title: 'Alert',
                                    message: "The last name field may not be greater than 50 characters.",
                                })

                                isRequired = true

                            }

                            if(isRequired){

                                // if(this.contactsList[i].first_name.length == 0 || this.contactsList[i].first_name.length > 50){

                                    this.contactsList[i].first_name = this.tempContact.first_name;

                                // }

                                // if(this.contactsList[i].last_name.length == 0 || this.contactsList[i].last_name.length > 50){

                                    this.contactsList[i].last_name = this.tempContact.last_name;

                                // }

                                this.tempContact = {
                                    id: '',
                                    isPreview: false,
                                    first_name: '',
                                    last_name: '',
                                    email: '',
                                    mobile_no: '',
                                    address: '',
                                    city:null,
                                    isLoading: false,
                                    isActive: false,
                                    isTouched:false,
                                }

                                return
                                
                            }

                            MessageBox.confirm(
                                'You have unsaved changes, save and proceed?',
                                'Confirm',
                                {
                                    distinguishCancelAndClose: true,
                                    confirmButtonText: 'Save',
                                    cancelButtonText: 'Discard Changes',
                                }
                            ).then(async () => {

                                let formData = new FormData();

                                formData.append('contact_id',contact.id);
                                formData.append('first_name',contact.first_name.replace(/^\w/, (c) => c.toUpperCase()).replace(/\s+/g,' ').trim());
                                formData.append('last_name',contact.last_name.replace(/^\w/, (c) => c.toUpperCase()).replace(/\s+/g,' ').trim());
                                formData.append('email',contact.email);
                                formData.append('isActive',contact.isActive ? 1 : 0);

                                if(contact.mobile_no){
                                    formData.append('mobile_no',contact.mobile_no);
                                }

                                if(contact.address){
                                    formData.append('address',contact.address);
                                }

                                if(contact.city){
                                    formData.append('city_id',contact.city_id);
                                }

                                let loader = Loading.service({
                                    text: "The contact is being updated. Please wait!",
                                    fullscreen: true,
                                });

                                try{
                                    let res = await this.$axios.post('/crm/contact/update',formData);
                                    if(res.data.status_code == "1462"){
                                        
                                        this.$notify({
                                            type: 'success',
                                            title: 'Success',
                                            message: res.data.message,
                                        })

                                        let contact = res.data.contact
                                        contact.isActive = contact.isActive ? true : false;

                                        let payload = {
                                            index: i,
                                            updatedContact: contact
                                        }

                                        this.$store.commit('contacts_module/update_contacts_list',payload);

                                        this.contactsList[i] = _.cloneDeep(contact)

                                        this.tempContact = {
                                            id: '',
                                            isPreview: false,
                                            first_name: '',
                                            last_name: '',
                                            email: '',
                                            mobile_no: '',
                                            address: '',
                                            city:null,
                                            isLoading: false,
                                            isActive: false,
                                            isTouched:false,
                                        };

                                    }
                                }catch(error){

                                    this.contactsList[i].first_name = this.tempContact.first_name;
                                    this.contactsList[i].last_name = this.tempContact.last_name;

                                    this.tempContact = {
                                        id: '',
                                        isPreview: false,
                                        first_name: '',
                                        last_name: '',
                                        email: '',
                                        mobile_no: '',
                                        address: '',
                                        city:null,
                                        isLoading: false,
                                        isActive: false,
                                        isTouched:false,
                                    }

                                    if(error.response.data.error.contact_id){

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            message: error.response.data.error.contact_id[0],
                                        });

                                    }else{

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            message: error.response.data.message,
                                        });

                                    }

                                }finally{

                                    loader.close();

                                }

                            }).catch((error)=>{

                                this.contactsList[i].first_name = this.tempContact.first_name;
                                this.contactsList[i].last_name = this.tempContact.last_name;

                                this.tempContact = {
                                    id: '',
                                    isPreview: false,
                                    first_name: '',
                                    last_name: '',
                                    email: '',
                                    mobile_no: '',
                                    address: '',
                                    city:null,
                                    isLoading: false,
                                    isActive: false,
                                    isTouched:false,
                                }

                                this.$notify({
                                    type: "info",
                                    title: "Changes Discarded",
                                    message: "Changes discarded successfully.",
                                });

                            });
                        
                        }

                    }

                }

            }

        },

        //contact no. methods starts here
        resetContactFieldValidations(){

            this.contactIsValid = true;
            this.countryIsSelected = true;
            this.contactFieldTouched = false;

        },
        validateContactNo(contactObj){

            if(typeof contactObj.valid != 'undefined'){

                this.contactIsValid = contactObj.valid;

                if(!this.contactIsValid){

                    this.contactFieldTouched = true;

                }else{

                    this.contactFieldTouched = false;

                }

            }
            
        },
        contactInputHandle(number,numberObj){

            if(typeof numberObj.country == 'undefined'){
            
                this.countryIsSelected = false;

            }else if(typeof numberObj.countryCode != 'undefined' && numberObj.countryCode == "--"){
            
                this.countryIsSelected = false;

            }else if(typeof numberObj.country != 'undefined' && 
                typeof numberObj.valid == 'undefined' &&
                numberObj.formatted.length == numberObj.country.dialCode + 1){
                
                this.countryIsSelected = false;

            }else{

                this.countryIsSelected = true;
            
            }

            this.selectedContactObj = numberObj;

        },
        dropDownOpenHandle(){

            this.countryObjBeforeChange = this.selectedContactObj;
            
        },
        dropDownCloseHandle(){

            if((this.previewForm.mobile_no).replace(/\s/g,'').length > this.selectedContactCountry.dialCode.length + 1){

                if((this.countryObjBeforeChange.country != this.selectedContactObj.country)){

                    this.previewForm.mobile_no = "+" + this.selectedContactCountry.dialCode;

                }

            }

            this.countryIsSelected = true;

        },
        countryChangeHandle(countryObj){

            this.selectedContactCountry = countryObj;

        },
        contactFieldFocusHandle(){

            this.contactFieldTouched = true;

        },
        //contact no. methods ends here

        //image related methods start
        resetImageValidations(){

            this.imageValidations = {
                imgIsRequired:false,
                imgInvalidFormat:false,
                isNotImg:false,
            }

        },
        async changeAddImage(e){

            this.imageValidations = {
                imgIsRequired:false,
                imgInvalidFormat:false,
                isNotImg:false,
            }

            if(e.target.files.length > 0){

                const file = e.target.files[0];

                if(file.name.length > 50){

                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: "Uploaded file name must not exceed 50 characters.",
                    }); 

                    if(this.$refs.addPreviewFileInput){

                        this.$refs.addPreviewFileInput.value = ''

                    }

                    this.imageValidations.imgIsRequired = false;

                    return

                }

                if(file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/webp' || file.type == 'image/png'){
                    
                    this.previewForm.addImage = file;
                    this.previewForm.addImage_src = URL.createObjectURL(file);

                    if(this.$refs.addPreviewFileInput){

                        this.$refs.addPreviewFileInput.value = ''

                    }

                    this.imageValidations.imgIsRequired = false;

                }else if(file.type.includes('image/')){
                    
                    let uploadedFileType = file.type.slice(6);
                    
                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: `${uploadedFileType.toUpperCase()} is not a valid file type.`,
                    });

                    if(this.$refs.addPreviewFileInput){

                        this.$refs.addPreviewFileInput.value = ''

                    }

                    // if(!this.previewForm.addImage){
                    //   this.imageValidations.imgIsRequired = true
                    // }
                    
                    // this.imageValidations.imgInvalidFormat = true

                }else{

                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: "Uploaded file must be an image.",
                    }); 

                    
                    if(this.$refs.addPreviewFileInput){

                        this.$refs.addPreviewFileInput.value = ''

                    }

                    // if(!this.previewForm.addImage){
                    //   this.imageValidations.imgIsRequired = true
                    // }

                    // this.imageValidations.isNotImg = true

                }

            }

        },
        async handleChangeEditImage(e){

            this.imageValidations = {
                imgIsRequired:false,
                imgInvalidFormat:false,
                isNotImg:false,
            }

            if(e.target.files.length > 0){

                const file = e.target.files[0];

                if(file.name.length > 50){

                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: "Uploaded file name must not exceed 50 characters.",
                    }); 

                    if(this.$refs.previewFileInput){

                        this.$refs.previewFileInput.value = ''

                    }

                    this.imageValidations.imgIsRequired = false;

                    return

                }

                if(file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/webp' || file.type == 'image/png'){
                
                    this.previewForm.image = file;
                    this.previewForm.image_src = URL.createObjectURL(file);

                    if(this.$refs.previewFileInput){

                        this.$refs.previewFileInput.value = ''

                    }

                    this.imageValidations.imgIsRequired = false;

                }else if(file.type.includes('image/')){

                    let uploadedFileType = file.type.slice(6);
                    
                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: `${uploadedFileType.toUpperCase()} is not a valid file type.`,
                    });

                    if(this.$refs.previewFileInput){

                        this.$refs.previewFileInput.value = ''

                    }

                    // if(!this.previewForm.image){
                    //   this.imageValidations.imgIsRequired = true
                    // }
                    
                    // this.imageValidations.imgInvalidFormat = true

                }else{

                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: "Uploaded file must be an image.",
                    }); 

                    if(this.$refs.previewFileInput){

                        this.$refs.previewFileInput.value = ''

                    }

                    // if(!this.previewForm.image){
                    //   this.imageValidations.imgIsRequired = true
                    // }

                    // this.imageValidations.isNotImg = true

                }

            }

        },
        //image related methods end
        
        //validating add/update form start
        async validate(form){

            if(form == 'preview'){

                this.$refs.contactObserver.validate().then(success => {

                    if(!success){

                        const errors = Object.entries(this.$refs.contactObserver.errors)
                        .map(([key, value]) => ({ key, value })).filter(error => error["value"].length);

                        this.$refs.contactObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

                        if(typeof this.selectedContactObj.valid == 'undefined' && this.previewForm.mobile_no.length > 0){

                            this.contactIsValid = false;
                            this.contactFieldTouched = true;

                            document.querySelector("#mobile-no-field").scrollIntoView({ behavior: 'smooth',block: "center" });
                            
                        }

                    }else{

                        if(this.previewForm.id == -1){

                            if(typeof this.selectedContactObj.valid == 'undefined' && this.previewForm.mobile_no.length > 0){

                                this.contactIsValid = false;
                                this.contactFieldTouched = true;

                            }

                            if(this.previewForm.mobile_no.length > 0 && this.contactIsValid && this.countryIsSelected){

                                this.contactAddHandle();

                            }else if (this.previewForm.mobile_no.length == 0){

                                this.contactAddHandle();

                            }else{

                                document.querySelector("#mobile-no-field").scrollIntoView({ behavior: 'smooth',block: "center" });

                            }

                        }else{

                            if(typeof this.selectedContactObj.valid == 'undefined' && this.previewForm.mobile_no.length > 0){

                                this.contactIsValid = false;
                                this.contactFieldTouched = true;
                            
                            }

                            if(this.previewForm.mobile_no.length > 0 && this.contactIsValid && this.countryIsSelected){

                                this.contactUpdateHandle();

                            }else if(this.previewForm.mobile_no.length == 0){

                                this.contactUpdateHandle();

                            }else{

                                document.querySelector("#mobile-no-field").scrollIntoView({ behavior: 'smooth',block: "center" });
                            
                            }

                        }

                    }

                });

            }else if(form == 'contactActivity'){

                this.$refs.contactActivityObserver.validate().then(success => {

                    if(!success){
                        
                        const errors = Object.entries(this.$refs.contactActivityObserver.errors)
                        .map(([key, value]) => ({ key, value })).filter(error => error["value"].length);

                        this.$refs.contactActivityObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

                    }else{

                        this.saveContactActivityHandle();

                    }

                });

            }

        },
        //validating add/update form end

        //preview form open/close & reset start
        previewContact(listedContact,i,field,e){

            if(field == 'input'){
            
                if((e.target.tagName == "TD" || e.target.dataset.open == "true") && !this.tourIsActive){
                    
                    if(listedContact.id != -1){

                        if(this.previewForm.id == -1){

                            MessageBox.confirm(
                                "Are you sure? unsaved changes will be discarded", 
                                "Warning", 
                                {
                                    confirmButtonText: "Yes",
                                    cancelButtonText: "Cancel",
                                    type: "warning",
                                }
                            ).then(()=>{

                                let index = this.contactsList.findIndex((contact) => contact.id == -1);

                                if(index != -1){
                                
                                    this.contactsList.splice(index,1); 

                                }

                                if(!this.isContactSaved && (this.tempPreviewContact.id != listedContact.id)){
                                
                                    let tempIndex = this.contactsList.findIndex(x=>x.id == this.tempPreviewContact.id);

                                    if(tempIndex != -1){

                                        this.contactsList[tempIndex] = this.tempPreviewContact;

                                    }

                                }

                                if(this.tempPreviewContact.id != '' && this.tempPreviewContact.id == listedContact.id){
                                
                                    let tempIndex = this.contactsList.findIndex(x=>x.id == this.tempPreviewContact.id);
                                
                                    this.contactsList[tempIndex] = _.cloneDeep(this.storedContacts[tempIndex]);

                                    let contact = _.cloneDeep(this.contactsList[tempIndex])

                                    this.resetContactFieldValidations();

                                    this.previewForm = this.contactsList[tempIndex]
                                    this.previewForm.index = i
                                    this.previewForm.mobile_no = this.contactsList[tempIndex].mobile_no ? this.contactsList[tempIndex].mobile_no : ''
                                    this.previewForm.image_src = contact.image ? contact.image : contact.image_src ? contact.image_src : null;
                                    this.previewForm.image = null;

                                    this.checkedItems = [];

                                    if(contact.city){

                                        if(contact.city.state){

                                            if(contact.city.state.country){

                                                this.selectedCountry = contact.city.state.country;

                                            }

                                        }

                                    }else{

                                        this.selectedCountry = null;

                                    }

                                    this.contactIsSaving = false;

                                    this.tempPreviewContact = _.cloneDeep(contact);

                                }else{

                                    let contact = listedContact

                                    this.resetContactFieldValidations();

                                    this.previewForm = contact
                                    this.previewForm.index = i
                                    this.previewForm.mobile_no = contact.mobile_no ? contact.mobile_no : ''
                                    this.previewForm.image_src = contact.image ? contact.image : contact.image_src ? contact.image_src : null;
                                    this.previewForm.image = null;

                                    this.checkedItems = []

                                    if(contact.city){

                                        if(contact.city.state){

                                            if(contact.city.state.country){

                                                this.selectedCountry = contact.city.state.country

                                            }

                                        }

                                    }else{

                                        this.selectedCountry = null

                                    }

                                    this.contactIsSaving = false;

                                    this.tempPreviewContact = _.cloneDeep(contact)

                                }

                            }).catch(()=>{})

                        }else{

                            let index = this.contactsList.findIndex((contact) => contact.id == -1);

                            if(index != -1){

                                this.contactsList.splice(index,1); 

                            }

                            if(!this.isContactSaved && (this.tempPreviewContact.id != listedContact.id)){
                                
                                let tempIndex = this.contactsList.findIndex(x=>x.id == this.tempPreviewContact.id);

                                if(tempIndex != -1){

                                    this.contactsList[tempIndex] = this.tempPreviewContact

                                }

                            }

                            if(this.tempPreviewContact.id != '' && this.tempPreviewContact.id == listedContact.id){

                                let tempIndex = this.contactsList.findIndex(x=>x.id == this.tempPreviewContact.id);
                                
                                this.contactsList[tempIndex] = _.cloneDeep(this.storedContacts[tempIndex])

                                let contact = _.cloneDeep(this.contactsList[tempIndex])

                                this.resetContactFieldValidations();

                                this.previewForm = this.contactsList[tempIndex]
                                this.previewForm.index = i
                                this.previewForm.mobile_no = this.contactsList[tempIndex].mobile_no ? this.contactsList[tempIndex].mobile_no : ''
                                this.previewForm.image_src = contact.image ? contact.image : contact.image_src ? contact.image_src : null;
                                this.previewForm.image = null;

                                this.checkedItems = []

                                if(contact.city){

                                    if(contact.city.state){

                                        if(contact.city.state.country){

                                            this.selectedCountry = contact.city.state.country

                                        }

                                    }

                                }else{

                                    this.selectedCountry = null

                                }

                                this.contactIsSaving = false;

                                this.tempPreviewContact = _.cloneDeep(contact)

                            }else{

                                let contact = listedContact

                                this.resetContactFieldValidations();

                                this.previewForm = contact
                                this.previewForm.index = i
                                this.previewForm.mobile_no = contact.mobile_no ? contact.mobile_no : ''
                                this.previewForm.image_src = contact.image ? contact.image : contact.image_src ? contact.image_src : null;
                                this.previewForm.image = null;

                                this.checkedItems = []

                                if(contact.city){

                                    if(contact.city.state){

                                        if(contact.city.state.country){

                                            this.selectedCountry = contact.city.state.country

                                        }

                                    }

                                }else{
                                    this.selectedCountry = null
                                }

                                this.contactIsSaving = false;

                                this.tempPreviewContact = _.cloneDeep(contact)

                            }

                        }

                    }

                }

            }else{

                if(!this.tourIsActive){
                    
                    if(listedContact.id != -1){

                        if(this.previewForm.id == -1){
                        
                            MessageBox.confirm(
                                "Are you sure? unsaved changes will be discarded", 
                                "Warning", 
                                {
                                    confirmButtonText: "Yes",
                                    cancelButtonText: "Cancel",
                                    type: "warning",
                                }
                            ).then(()=>{

                                let index = this.contactsList.findIndex((contact) => contact.id == -1);

                                if(index != -1){

                                    this.contactsList.splice(index,1); 

                                }

                                if(!this.isContactSaved && (this.tempPreviewContact.id != listedContact.id)){

                                    let tempIndex = this.contactsList.findIndex(x=>x.id == this.tempPreviewContact.id);

                                    if(tempIndex != -1){

                                        this.contactsList[tempIndex] = this.tempPreviewContact

                                    }

                                }

                                if(this.tempPreviewContact.id != '' && this.tempPreviewContact.id == listedContact.id){

                                    let tempIndex = this.contactsList.findIndex(x=>x.id == this.tempPreviewContact.id);

                                    this.contactsList[tempIndex] = _.cloneDeep(this.storedContacts[tempIndex])


                                    let contact = _.cloneDeep(this.contactsList[tempIndex])

                                    this.resetContactFieldValidations();

                                    this.previewForm = this.contactsList[tempIndex]
                                    this.previewForm.index = i
                                    this.previewForm.mobile_no = this.contactsList[tempIndex].mobile_no ? this.contactsList[tempIndex].mobile_no : ''
                                    this.previewForm.image_src = contact.image ? contact.image : contact.image_src ? contact.image_src : null;
                                    this.previewForm.image = null;

                                    this.checkedItems = []

                                    if(contact.city){

                                        if(contact.city.state){

                                            if(contact.city.state.country){

                                                this.selectedCountry = contact.city.state.country

                                            }

                                        }

                                    }else{

                                        this.selectedCountry = null

                                    }

                                    this.contactIsSaving = false;

                                    this.tempPreviewContact = _.cloneDeep(contact)

                                }else{

                                    let contact = listedContact

                                    this.resetContactFieldValidations();

                                    this.previewForm = contact
                                    this.previewForm.index = i
                                    this.previewForm.mobile_no = contact.mobile_no ? contact.mobile_no : ''
                                    this.previewForm.image_src = contact.image ? contact.image : contact.image_src ? contact.image_src : null;
                                    this.previewForm.image = null;

                                    this.checkedItems = []

                                    if(contact.city){

                                        if(contact.city.state){

                                            if(contact.city.state.country){

                                                this.selectedCountry = contact.city.state.country

                                            }

                                        }

                                    }else{

                                        this.selectedCountry = null

                                    }

                                    this.contactIsSaving = false;

                                    this.tempPreviewContact = _.cloneDeep(contact)

                                }

                            }).catch(()=>{})

                        }else{

                            let index = this.contactsList.findIndex((contact) => contact.id == -1);

                            if(index != -1){

                                this.contactsList.splice(index,1); 

                            }

                            if(!this.isContactSaved && (this.tempPreviewContact.id != listedContact.id)){
                                
                                let tempIndex = this.contactsList.findIndex(x=>x.id == this.tempPreviewContact.id);

                                if(tempIndex != -1){

                                    this.contactsList[tempIndex] = this.tempPreviewContact

                                }

                            }

                            if(this.tempPreviewContact.id != '' && this.tempPreviewContact.id == listedContact.id){

                                let tempIndex = this.contactsList.findIndex(x=>x.id == this.tempPreviewContact.id);
                                
                                this.contactsList[tempIndex] = _.cloneDeep(this.storedContacts[tempIndex])


                                let contact = _.cloneDeep(this.contactsList[tempIndex])

                                this.resetContactFieldValidations();

                                this.previewForm = this.contactsList[tempIndex]
                                this.previewForm.index = i
                                this.previewForm.mobile_no = this.contactsList[tempIndex].mobile_no ? this.contactsList[tempIndex].mobile_no : ''
                                this.previewForm.image_src = contact.image ? contact.image : contact.image_src ? contact.image_src : null;
                                this.previewForm.image = null;

                                this.checkedItems = []

                                if(contact.city){

                                    if(contact.city.state){

                                        if(contact.city.state.country){

                                            this.selectedCountry = contact.city.state.country

                                        }

                                    }

                                }else{

                                    this.selectedCountry = null

                                }

                                this.contactIsSaving = false;

                                this.tempPreviewContact = _.cloneDeep(contact)

                            }else{

                                let contact = listedContact

                                this.resetContactFieldValidations();

                                this.previewForm = contact
                                this.previewForm.index = i
                                this.previewForm.mobile_no = contact.mobile_no ? contact.mobile_no : ''
                                this.previewForm.image_src = contact.image ? contact.image : contact.image_src ? contact.image_src : null;
                                this.previewForm.image = null;

                                this.checkedItems = []

                                if(contact.city){

                                    if(contact.city.state){

                                        if(contact.city.state.country){

                                            this.selectedCountry = contact.city.state.country

                                        }

                                    }

                                }else{

                                    this.selectedCountry = null

                                }

                                this.contactIsSaving = false;

                                this.tempPreviewContact = _.cloneDeep(contact)

                            }

                        }

                    }

                }

            }

        },
        previewSearchedContact(listedContact,i){

            let index = this.contactsList.findIndex((contact) => contact.id == -1);

            if(index != -1){

                this.contactsList.splice(index,1); 

            }

            let contact = listedContact

            this.resetContactFieldValidations();

            this.previewForm = contact
            this.previewForm.index = i
            this.previewForm.mobile_no = contact.mobile_no ? contact.mobile_no : ''
            this.previewForm.image_src = contact.image ? contact.image : contact.image_src ? contact.image_src : null;
            this.previewForm.image = null;

            this.checkedItems = []

            if(contact.city){

                if(contact.city.state){

                    if(contact.city.state.country){

                        this.selectedCountry = contact.city.state.country

                    }

                }

            }else{

                this.selectedCountry = null

            }

            this.contactIsSaving = false;

            this.tempPreviewContact = _.cloneDeep(contact)

        },
        closePreview(){

            if(this.previewForm.isTouched){
            
                MessageBox.confirm(
                    "Do you want to close? Entered information will be discarded.", 
                    "Warning", 
                    {
                        confirmButtonText: "Yes",
                        cancelButtonText: "Cancel",
                        type: "warning",
                    }
                ).then(async () => {

                    let index = this.contactsList.findIndex((contact) => contact.id == -1);

                    if(index != -1){

                        this.contactsList.splice(index,1); 

                    }

                    if(!this.isContactSaved){

                        let tempIndex = this.contactsList.findIndex(x=>x.id == this.tempPreviewContact.id);

                        if(tempIndex != -1){

                            this.contactsList[tempIndex] = this.tempPreviewContact;

                        }

                    }

                    this.previewForm = {
                        id: '',
                        isPreview: false,
                        first_name: '',
                        last_name: '',
                        email: '',
                        mobile_no: '',
                        address: '',
                        image: '',
                        image_src: '',
                        addImage:null,
                        addImage_src:'',
                        city:null,
                        isLoading: false,
                        isActive: false,
                        isTouched:false,
                    }

                    this.selectedCountry = null;
                    this.selectedState = null;
                    this.selectedCity = null;
                    this.isContactSaved = false;

                    this.isMasterSearched = false;

                    this.contactIsSaving = false;

                    if(this.$refs.contactInputfield){

                        this.$refs.contactInputfield.resetValues();

                    }

                    if(this.$refs.contactObserver){

                        this.$refs.contactObserver.reset();

                    }

                    this.resetImageValidations()


                }).catch(()=>{})

            }else{

                let index = this.contactsList.findIndex((contact) => contact.id == -1);

                if(index != -1){

                    this.contactsList.splice(index,1); 

                }

                if(!this.isContactSaved){

                    let tempIndex = this.contactsList.findIndex(x=>x.id == this.tempPreviewContact.id);

                    if(tempIndex != -1){

                        this.contactsList[tempIndex] = this.tempPreviewContact;

                    }

                }

                this.previewForm = {
                    id: '',
                    isPreview: false,
                    first_name: '',
                    last_name: '',
                    email: '',
                    mobile_no: '',
                    address: '',
                    image: '',
                    image_src: '',
                    addImage:null,
                    addImage_src:'',
                    city:null,
                    isLoading: false,
                    isActive: false,
                    isTouched:false,
                }

                this.selectedCountry = null
                this.selectedState = null
                this.selectedCity = null
                this.isContactSaved = false

                this.isMasterSearched = false

                this.contactIsSaving = false

                if(this.$refs.contactInputfield){

                    this.$refs.contactInputfield.resetValues();

                }

                if(this.$refs.contactObserver){

                    this.$refs.contactObserver.reset();

                }

            }

        },
        resetPreview(){

            let index = this.contactsList.findIndex((contact) => contact.id == -1);

            if(index != -1){

                this.contactsList.splice(index,1); 

            }

            if(!this.isContactSaved){

                let tempIndex = this.contactsList.findIndex(x=>x.id == this.tempPreviewContact.id);

                if(tempIndex != -1){

                    this.contactsList[tempIndex] = this.tempPreviewContact;

                }

            }

            this.previewForm = {
                id: '',
                isPreview: false,
                first_name: '',
                last_name: '',
                email: '',
                mobile_no: '',
                address: '',
                image: '',
                image_src: '',
                addImage:null,
                addImage_src:'',
                city:null,
                isLoading: false,
                isActive: false,
                isTouched:false,
            }

            this.selectedCountry = null;
            this.selectedState = null;
            this.selectedCity = null;

            this.isContactSaved = false;

            this.contactIsSaving = false;

            this.resetContactFieldValidations();

            if(!this.isMasterSearched){

                if(this.$refs.contactObserver){

                    this.$refs.contactObserver.reset()

                }

            }

            this.resetImageValidations();

        },
        createContact(){

            if(this.contactsLoading){

                this.$notify({
                    type: "info",
                    title: "Please Wait",
                    message: "Contacts are being retrieved.",
                });
                
                return

            }

            if(this.contactIsSaving){

                this.$notify({
                    type: "info",
                    title: "Please Wait",
                    message: "A contact is currently being processed.",
                });

                return

            }

            let index = this.contactsList.findIndex(x=>x.id == -1);

            if(index != -1){

                // this.$notify({
                //   title: "Can't Add New Contact",
                //   type: "error",
                //   message: "you have an unsaved contact in the list",
                // });

                return

            }

            this.closePreview();

            this.previewForm = {
                id: -1,
                isPreview: false,
                first_name: '',
                last_name: '',
                email: '',
                mobile_no: '',
                address: '',
                image: '',
                image_src: '',
                addImage:null,
                addImage_src:'',
                city:null,
                isLoading: false,
                isActive: false,
                isTouched:false,
            }

            // this.countriesList.forEach((country)=>{
                
            //     if(this.settings.country.name == country.name){
            //         if(this.settings.country.phonecode.startsWith("+")){
            //             this.previewForm.mobile_no = this.settings.country.phonecode.replace(/-/g, '')
            //         }else{
            //             this.previewForm.mobile_no = "+" + (this.settings.country.phonecode).replace(/-/g, '')
            //         }
            //     }

            // });

            let tempContact = this.previewForm;

            this.contactsList.unshift(tempContact);

            this.selectedCountry = null;
            this.selectedState = null;
            this.selectedCity = null;


            if(this.$refs.contactObserver){

                this.$refs.contactObserver.reset();

            }

            this.checkedItems = [];

        },
        //preview form open/close & reset end

        //contact activities methods start
        previewContactActivities(listedContact,i){

            this.contactActivitiesModal = {
                contact:null,
                activities:[],
                isOpen:false,
            }

            this.emailDetailsSection = {
                template_html:'',
                isOpen:false,
                pending:false,
            }

            this.contactActivitiesModal.contact = listedContact
            this.contactActivitiesModal.activities = listedContact.activities
            this.contactActivitiesModal.isOpen = true

            setTimeout(()=>{

                let element = document.querySelectorAll(".emailSubject");

                element.forEach((elem) =>{

                    elem.addEventListener('click',this.emailDetailsHandle.bind(this));

                });

            },100)

        },
        openContactActivityForm(form,activity){

            if(form == 'add'){

                this.contactActivityForm = {
                    contact_id:this.contactActivitiesModal.contact.id,
                    activity_id:'',
                    title:'',
                    description:'',
                    type:'',
                    isOpen:true,
                    pending:false,
                    isEdit:false,
                }

                this.$refs.contactActivityObserver.reset();

            }else if(form == 'edit'){

                this.contactActivityForm = {
                    contact_id:this.contactActivitiesModal.contact.id,
                    activity_id:activity.id,
                    title:activity.title,
                    description:activity.description,
                    type:activity.type,
                    isOpen:true,
                    pending:false,
                    isEdit:true,
                }

                this.$refs.contactActivityObserver.reset();

            }

        },
        closeContactActivityModal(){

            this.contactActivityForm = {
                contact_id:'',
                activity_id:'',
                title:'',
                description:'',
                type:'',
                isOpen:false,
                pending:false,
                isEdit:false,
            }

        },

        async saveContactActivityHandle(){

            if(this.contactActivityForm.isEdit){

                let formData = new FormData();

                formData.append('activity_log_id',this.contactActivityForm.activity_id);
                formData.append('title',this.contactActivityForm.title);
                formData.append('description',this.contactActivityForm.description);
                formData.append('type',this.contactActivityForm.type);

                this.contactActivityForm.pending = true;
                try{
                    let res = await this.$axios.post('/crm/contact/log/update',formData);
                    if(res.data.status_code == "1467"){

                        this.$notify({
                            type: 'success',
                            title: 'Success',
                            message: res.data.message,
                        });

                        let payload = {
                            contactId: this.contactActivityForm.contact_id,
                            activity: res.data.activity_log
                        }

                        let activityIndex = this.contactActivitiesModal.activities.findIndex(activity => activity.id == res.data.activity_log.id)

                        if(activityIndex != -1){

                            this.contactActivitiesModal.activities[activityIndex] = res.data.activity_log;

                        }

                        this.$store.commit('contacts_module/update_contact_activity',payload);

                        this.$store.dispatch('contacts_module/fetchContactTypesRequest');

                        this.contactActivityForm.pending = false;
                        this.contactActivityForm.isOpen = false;

                    }
                }catch(error){

                    this.contactActivityForm.pending = false;

                    if(error.response){

                        if(error.response.data.error.crm_contact_id){

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.error.crm_contact_id[0],
                            });

                        }else if(error.response.data.error.activity_log_id){

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.error.activity_log_id[0],
                            });

                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.message,
                            });

                        }

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.message,
                        });

                    }

                }
                
            }else{

                let formData = new FormData();

                formData.append('crm_contact_id',this.contactActivityForm.contact_id);
                formData.append('title',this.contactActivityForm.title);
                formData.append('description',this.contactActivityForm.description);
                formData.append('type',this.contactActivityForm.type);

                this.contactActivityForm.pending = true
                try{
                    let res = await this.$axios.post('/crm/contact/log/add',formData);
                    if(res.data.status_code == "1466"){

                        this.$notify({
                            type: 'success',
                            title: 'Success',
                            message: res.data.message,
                        });

                        let payload = {
                            contactId: this.contactActivityForm.contact_id,
                            activity: res.data.activity_log
                        }

                        this.contactActivitiesModal.activities.unshift(res.data.activity_log);

                        this.$store.commit('contacts_module/add_contact_activity',payload);

                        this.$store.dispatch('contacts_module/fetchContactTypesRequest')

                        this.closeContactActivityModal();

                    }
                }catch(error){

                    this.contactActivityForm.pending = false

                    if(error.response){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.message,
                        });

                    }

                }

            }

        },
        async removeContactActivityHandle(listedActivity,i){

            MessageBox.confirm(
                'Are you sure you want to delete the contact activity?',
                'Warning',
                {
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'Cancel',
                    type: 'warning',
                }
            ).then(async ()=>{

                this.deletingActivity.push(listedActivity.id);

                this.activityIsRemoving = true;
                try{
                    let res = await this.$axios.delete(`/crm/contact/log/remove/${listedActivity.id}`);
                    if(res.data.status_code == '1468'){

                        this.$notify({
                            type: 'success',
                            title: 'Success',
                            message: res.data.message,
                        });

                        let payload = {
                            contactId: this.contactActivitiesModal.contact.id,
                            activity:listedActivity,
                        }

                        this.contactActivitiesModal.activities.splice(i,1)

                        this.$store.commit('contacts_module/remove_contact_activity',payload);

                        this.$store.dispatch('contacts_module/fetchContactTypesRequest')

                    }
                }catch(error){

                    if(error.response){

                        if(error.response.data.error.activity_log_id){

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.error.activity_log_id[0],
                            });

                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.message,
                            });

                        }

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.message,
                        });

                    }

                }finally{

                    loader.close();

                    this.deletingActivity = [];

                    this.activityIsRemoving = false

                }

            }).catch(()=>{})

        },

        async emailDetailsHandle(e){

            let formData = new FormData();

            let emailId = e.target.dataset.email;

            formData.append('contact_id',this.contactActivitiesModal.contact.id);
            formData.append('email_id',emailId);

            this.emailDetailsSection.pending = true;
            this.emailDetailsSection.isOpen = true;

            try{
                let res = await this.$axios.post('crm/contact/email/details',formData);
                if(res.data.status_code == "1464"){

                    this.emailDetailsSection = {
                        template_html: res.data.email_html,
                        isOpen:true,
                        pending:false,
                    }

                }

            }catch(error){
                
                this.closeEmailDetailsSection();

                if(error.response){

                    if(error.response.data.error && error.response.data.error.includes('contact has no emails')){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error,
                        });

                    }else if(error.response.data.message){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: "Something went wrong. Try again later.",
                        });

                    }

                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }

            }

        },
        closeEmailDetailsSection(){

            setTimeout(()=>{

                let element = document.querySelectorAll(".emailSubject");

                element.forEach((elem) =>{

                    elem.addEventListener('click',this.emailDetailsHandle.bind(this));

                });

            },100);

            this.emailDetailsSection.isOpen = false;
            this.emailDetailsHandle.pending =false;

        },
        //contact activities methods end

        async contactAddHandle(){

            let formData = new FormData();

            formData.append('first_name',this.previewForm.first_name.replace(/^\w/, (c) => c.toUpperCase()).replace(/\s+/g,' ').trim());
            formData.append('last_name',this.previewForm.last_name.replace(/^\w/, (c) => c.toUpperCase()).replace(/\s+/g,' ').trim());
            formData.append('email',this.previewForm.email);
            formData.append('isActive',this.previewForm.isActive ? 1 : 0);
            
            if(this.previewForm.mobile_no){

                formData.append('mobile_no',this.previewForm.mobile_no.replace(/\s/g,''));

            }

            if(this.previewForm.address){

                formData.append('address',this.previewForm.address.replace(/\s+/g,' ').trim());

            }

            if(this.selectedCity){

                formData.append('city_id',this.selectedCity.id);

            }


            if(this.previewForm.addImage){

                formData.append('image',this.previewForm.addImage);

            }

            this.contactIsSaving = true

            try{

                let res = await this.$axios.post('/crm/contact/add',formData);
                if(res.data.status_code == "1461"){

                    this.contactIsSaving = false;

                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: res.data.message,
                    });

                    let contact = res.data.contact
                    contact.isActive = contact.isActive ? true : false;

                    this.isContactSaved = true;

                    this.resetPreview();

                    this.$store.commit('contacts_module/add_contacts_list',contact);

                    this.$store.commit('gallery_module/CLEAR_MODULE');

                    this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                    this.$store.dispatch('contacts_module/fetchContactTypesRequest')

                }

            }catch(error){

                this.contactIsSaving = false

                if(error.response){
                    
                    if(error.response.data.error.email){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.email[0],
                        });

                    }else if(error.response.data.error.mobile_no){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.mobile_no[0],
                        });

                    }else if(typeof error.response.data.error == 'string' && error.response.data.error.includes('storage limit has been reached')){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error,
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }
                

            }

        },
        async contactUpdateHandle(){

            let formData = new FormData();

            formData.append('contact_id',this.previewForm.id);
            formData.append('first_name',this.previewForm.first_name.replace(/^\w/, (c) => c.toUpperCase()).replace(/\s+/g,' ').trim());
            formData.append('last_name',this.previewForm.last_name.replace(/^\w/, (c) => c.toUpperCase()).replace(/\s+/g,' ').trim());
            formData.append('isActive',this.previewForm.isActive ? 1 : 0);

            if(this.previewForm.mobile_no){

                formData.append('mobile_no',this.previewForm.mobile_no.replace(/\s/g,''));

            }

            if(this.previewForm.address){

                formData.append('address',this.previewForm.address.replace(/\s+/g,' ').trim());

            }

            if(this.selectedCity){

                formData.append('city_id',this.selectedCity.id);

            }

            if(this.previewForm.image){

                formData.append('image',this.previewForm.image)

            }

            this.contactIsSaving = true
            try{

                let res = await this.$axios.post('/crm/contact/update',formData);
                if(res.data.status_code == '1462'){
                    
                    this.contactIsSaving = false;

                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: res.data.message,
                    });

                    let contact = res.data.contact
                    contact.isActive = contact.isActive ? true : false;

                    let i = this.contactsList.findIndex(x=>x.id == contact.id)

                    this.contactsList[i] = _.cloneDeep(contact)

                    let payload = {
                        index: i,
                        updatedContact: contact
                    }

                    this.$store.commit('contacts_module/update_contacts_list',payload);

                    this.$store.commit('gallery_module/CLEAR_MODULE');

                    this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                    this.$store.dispatch('contacts_module/fetchContactTypesRequest');

                    this.$store.commit('groups_module/CLEAR_MODULE');

                    if(this.previewForm.id == contact.id){

                        this.isContactSaved = true;

                        this.resetPreview();

                    }

                }
            }catch(error){

                this.contactIsSaving = false

                if(error.response){

                    if(error.response.data.error.contact_id){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.contact_id[0],
                        });

                    }else if(error.response.data.error.mobile_no){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.mobile_no[0],
                        });

                    }else if(error.response.data.error.includes('storage limit has been reached')){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error,
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }

            }

        },
        contactDeleteHandle(contact,index){

            MessageBox.confirm(
                'Are you sure you want to delete the contact?',
                'Warning',
                {
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'Cancel',
                    type: 'warning',
                }
            ).then(async ()=>{

                this.deletingItems.push(contact.id);
                this.deleteLoader = true;

                let loader = Loading.service({
                    text: "The contact is being removed. Please wait!",
                    fullscreen: true,
                });

                try{
                    let res = await this.$axios.delete(`/crm/contact/remove/${contact.id}`);
                    if(res.data.status_code == "1463"){

                        this.$notify({
                            type: 'success',
                            title: 'Success',
                            message: res.data.message,
                        });

                        // this.$store.commit('contacts_module/delete_contacts_list',index);
                        
                        this.$store.commit('gallery_module/CLEAR_MODULE');

                        this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                        this.$store.dispatch('contacts_module/fetchContactTypesRequest');

                        this.deleteLoader = false;
                        this.deletingItems = [];

                        //removing deleted item from checked items list
                        this.checkedItems = this.checkedItems.filter((item) => {

                            if(item != contact.id){
                                
                                return item;
                                
                            }

                        });

                        this.contactsLoading = true;

                        this.$store.dispatch('contacts_module/fetchStoreContactsRequest',{ size: 15 ,page: 1, loadData:'new' });

                    }
                }catch(error){

                    this.deleteLoader = false;
                    this.deletingItems = [];

                    if(error.response){

                        if(error.response.data.error.contact_id){

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.error.contact_id[0],
                            });

                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.message,
                            });

                        }

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.message,
                        });

                    }

                }finally{

                    loader.close();

                }

            }).catch(()=>{});

        },

        //activity types methods start
        addActivityType(newType){

            this.activityTypesList.unshift(newType);

            this.contactActivityForm.type = newType;

        },
        //activity types methods start

        //check/uncheck & bulk action start
        checkedItemHandle(contactId){
            
            if(!this.tourIsActive && contactId != -1 && !this.previewForm.id){
                
                let index = this.checkedItems.findIndex(item => item == contactId);
                
                if(index != -1){

                    this.checkedItems.splice(index,1);

                }else{

                    this.checkedItems.push(contactId);

                }

            }

        },
        toggleIsCheckedAll(){

            if(!this.tourIsActive ){

                if(this.checkedItems.length == this.contactsList.length){
                    
                    this.checkedItems = [];

                }else if(this.checkedItems.length < this.contactsList.length){
                    
                    this.checkedItems = [];
                    this.contactsList.forEach((contact) => {

                        this.checkedItems.push(contact.id);

                    });
                    
                }

            }

        },
        clearSelection(){

            MessageBox.confirm(
                'Do you really want to uncheck the selected records?',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }
            ).then(async () => {

                this.checkedItems = [];

            }).catch(() => {})

        },
        async deleteBulkContacts(){

            this.resetPreview()

            MessageBox.confirm(
                'Do you really want to make the changes?',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }
            ).then(async ()=>{

                let formData = new FormData();

                formData.append('resource_type', 'crm_contact');
                formData.append("resource_ids", JSON.stringify(this.checkedItems));
                formData.append('action','delete');

                this.deletingItems = _.cloneDeep(this.checkedItems)
                this.deleteLoader = true;

                let loader = Loading.service({
                    text: "The selected contacts are being removed. Please wait!",
                    fullscreen: true,
                });

                try{
                    let res = await this.$axios.post('/bulk-action',formData);
                    if(res.data.status_code == "2031"){

                        this.$notify({
                            type: 'success',
                            title: 'Success',
                            message: "The CRM contacts have been successfully removed.",
                        });

                        // let payload = {
                        //     listIds: this.checkedItems
                        // }

                        // this.$store.commit('contacts_module/contacts_bulk_action',payload);

                        this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                        this.$store.dispatch('contacts_module/fetchContactTypesRequest');

                        this.$store.dispatch('groups_module/CLEAR_MODULE');

                        this.$store.commit('gallery_module/CLEAR_MODULE');

                        this.checkedItems = [];
                        this.deletingItems = [];
                        this.deleteLoader = false;

                        this.contactsLoading = true;

                        this.$store.dispatch('contacts_module/fetchStoreContactsRequest',{ size: 15 ,page: 1, loadData:'new' });

                    }
                }catch(error){

                    this.deletingItems = [];
                    this.deleteLoader = false;

                    if(error.response){

                        if(error.response.data.message){

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.message,
                            });

                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: "One of the contacts may have been deleted or isn't available.",
                            });

                        }

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.message,
                        });

                    }

                }finally{

                    loader.close();

                }

            }).catch(()=>{})

        },
        //check/uncheck & bulk action end

        //contact searching start
        searchContact(keyword){

            this.$refs.module_search.searchedKeyword = keyword
            
            this.$refs.module_search.search();

        },
        //contact searching end

        //tour methods start
        startContactsTour(){

            this.$tours['contactsTour'].start();

            this.tourIsActive = true

            if(this.previewForm.id != ''){

                this.resetPreview();

            }

        },
        handleNextStep(currentStep){

            if(currentStep + 1 == 7){

                this.createContact();

                document.querySelector(".tableActionBtns").style.position = "static";

                document.querySelector(".productSlideBox").style.overflowY  = "hidden";

            }

            if(currentStep + 1 == 19){

                document.querySelector(".tableActionBtns").style.position = "sticky";

            }

            if(currentStep + 1 == 20){
                
                this.expand();

            }

            if(currentStep + 1 == 21){
                
                if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){
                
                    this.expand();
                
                }
            
            }

        },
        handlePreviousStep(currentStep){

            if(currentStep == 7){

                this.resetPreview();

                document.querySelector(".tableActionBtns").style.position = "sticky";

                document.querySelector(".productSlideBox").style.overflowY  = "auto";

            }

            if(currentStep == 19){

                document.querySelector(".tableActionBtns").style.position = "static";

            }

            if(currentStep == 21){

                this.expand();

            }

            if(currentStep == 20){

                if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.expand();

                }

            }

        },
        handleTourStop(){

            document.querySelector(".tableActionBtns").style.position = "sticky";

            document.querySelector(".productSlideBox").style.overflowY  = "auto";

            this.resetPreview();

            this.tourIsActive = false;

        },
        handleTourFinish(){

            document.querySelector(".tableActionBtns").style.position = "sticky";

            document.querySelector(".productSlideBox").style.overflowY  = "auto";

            this.resetPreview();

            this.tourIsActive = false;

        },
        handleTourSkip(){

            document.querySelector(".tableActionBtns").style.position = "sticky";

            document.querySelector(".productSlideBox").style.overflowY  = "auto";

            this.resetPreview();

            this.tourIsActive = false;

        },
        //tour methods end

        //other methods start
        isDeletingItem(id){

            let find = this.deletingItems.find(x=>x == id);

            if(find){

                return true;

            }else{

                return false;

            }

        },
        isDeletingActivity(id){

            let find = this.deletingActivity.find(x=>x == id);

            if(find){

                return true;

            }else{

                return false;

            }

        },
        async loadStates(){

            this.statesLoading = true;
            try{
                let res = await this.$axios.get('states/all',{params:{country_id:this.selectedCountry.id}});
                if(res.data.status_code == "1010"){

                    this.listStates = res.data.states

                    if(this.selectedCountry && this.tempPreviewContact.city && this.tempPreviewContact.city.state.country_id == this.selectedCountry.id){

                        let stateObj = {
                            id: this.tempPreviewContact.city.state.id,
                            name: this.tempPreviewContact.city.state.name,
                            country_code: this.tempPreviewContact.city.state.country_code,
                            country_id: this.tempPreviewContact.city.state.country_id,
                            fips_code: this.tempPreviewContact.city.state.fips_code,
                            iso2: this.tempPreviewContact.city.state.iso2,
                            latitude: this.tempPreviewContact.city.state.latitude,
                            longitude: this.tempPreviewContact.city.state.longitude,
                            wikiDataId: this.tempPreviewContact.city.state.wikiDataId,
                        }

                        this.selectedState = stateObj

                    }

                }
            }catch(error){

                this.$message({
                    type: "error",
                    showClose: true,
                    message: error.response ? error.response.data.message : error.message,
                });

            }finally{

                this.statesLoading = false;

            }

        },
        async loadCities(){

            this.citiesLoading = true;
            try{
                let res = await this.$axios.get('cities/all',{params:{state_id:this.selectedState.id}});
                if(res.data.status_code == "1010"){

                    this.listCities = res.data.cities

                    if(this.selectedCountry && this.tempPreviewContact.city && this.tempPreviewContact.city.state.country_id == this.selectedCountry.id){
                        
                        let cityObj = {
                            id: this.tempPreviewContact.city.id,
                            country_id: this.tempPreviewContact.city.country_id,
                            country_code: this.tempPreviewContact.city.country_code,
                            name: this.tempPreviewContact.city.name,
                            state_id: this.tempPreviewContact.city.state_id,
                            state_code: this.tempPreviewContact.city.state_code,
                            latitude: this.tempPreviewContact.city.latitude,
                            longitude: this.tempPreviewContact.city.longitude,
                            wikiDataId: this.tempPreviewContact.city.wikiDataId,
                        }

                        this.selectedCity = cityObj
                        
                    }

                }
            }catch(error){

                this.$message({
                    type: "error",
                    showClose: true,
                    message: error.response ? error.response.data.message : error.message,
                });

            }finally{

                this.citiesLoading = false;

            }

        },
        expand(){

            this.$refs.viewInputForm.classList.toggle('fullWidthSlide');

        },
        contactsKeyEvent(e){

            if(e.key == 'Escape'){

                if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.$refs.viewInputForm.classList.remove('fullWidthSlide');

                }else if(this.$refs.viewInputForm.classList.contains('activeRow')){

                    this.closePreview();

                }
            }

        },
        //other methods end

        //image loading error handling
        handleImageError(e){

            e.target.src = '/images/image_not_found.png'

        },

    },
    mounted(){

        this.$refs.contactsTable.addEventListener('scroll', this.handleScrollEvent);

        window.addEventListener('keydown',this.contactsKeyEvent);

        window.addEventListener('beforeunload', (event) => {

            let index = null;

            this.contactsList.forEach((contact) => {

                if(contact.id == -1){

                    index = this.contactsList.indexOf(contact);

                }

            });

            if(this.previewForm.id == -1 || index != null){

                event.returnValue = "Are you sure you want to leave? entered information will be lost";

            }

        });

        if(this.$route.query.search){
            
            this.$refs.module_search.searchedKeyword = this.$route.query.search

            if(this.$route.query.preview == 1){

                this.isMasterSearched = true;

            }

            this.$router.replace({'query': null});

            this.$refs.module_search.search();

        }

    },
    beforeMount(){

        try{

            if((!this.storedContacts || this.storedContacts.length == 0) && this.contactsSearchKeyword.length == 0){
            
                this.contactsLoading = true;

                this.$store.dispatch('contacts_module/fetchStoreContactsRequest',{ size: 15 ,page: 1, loadData:'new' });

            }else{

                this.contactsList = _.cloneDeep(this.storedContacts);

                this.contactsLoading = false;

            }

            if(!this.storeAllCountries || this.storeAllCountries.length == 0){

                this.$store.dispatch('countries_module/fetchAllCountries')

            }else{

                this.countriesList = this.storeAllCountries;

                this.countriesList.forEach((country) => {

                    this.allowedCountries.push(country.iso2);

                });

            }

            if(!this.storedActivityTypes || this.storedActivityTypes.length == 0){

                this.$store.dispatch('contacts_module/fetchContactTypesRequest');

            }else{

                this.activityTypesList = _.cloneDeep(this.storedActivityTypes);

            }

        }catch(error){

            this.contactsLoading = false;

            this.$message({
                type: 'error',
                showClose: true,
                message: error.response ? error.response.data.message : error.message,
            });

        }

    },
    beforeDestroy(){

        window.removeEventListener("keydown", this.contactsKeyEvent);

    },
    beforeRouteLeave(to, from, next) {

        let index = null;

        this.contactsList.forEach((contact) => {

            if(contact.id == -1){

                index = this.contactsList.indexOf(contact);
                
            }

        });

        if(window.innerWidth <= 1024){

            document.body.classList.remove('activeIpad');
            document.body.classList.add('hideSideBar');

        }

        if((this.previewForm.id || index != null) && to.fullPath != '/login'){

            MessageBox.confirm(
                'You may have unsaved changes, Proceeding will discard them',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Process',
                    cancelButtonText: 'Stay',
                }
            ).then(()=>{
                
                this.resetPreview();

                next();
                
            }).catch(()=>{
                
            })

        }else{

            next()

        }
    },
}
window.myFunction = function (event) {
    // reset all menu items
    document.querySelectorAll(".sectionTabsLink ul li a.active").forEach(function (item) {
        item.classList.remove("active");
    });
    // mark as active selected menu item
    event.target.classList.add("active");
};
</script>

<style scoped>
    .el-timeline{
        max-height: 600px;
        overflow-y: auto;
    }
</style>